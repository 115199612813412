import { useCallback } from 'react';
import { useHistory } from "react-router";
import Button from "../../../Components/Buttons/Button";
import { ContainerLayout } from '../../../Layouts/Container/ContainerLayout';
import "./CTACard.css";

interface ICTACardProps {
    text: string;
    actionText: string;
    url: string;
    //possible custom onclick!
}

export function CTACard(props: ICTACardProps) {
    let history = useHistory();

    const onClickRedirect = useCallback((path: string) => {
        history.push(path);
    },[]);

    return (
        <ContainerLayout className="subscriptionCard-container">
            <div className="subscriptionCard-content">
                <div className="subscription_text">{props.text}</div>
                <Button name={props.actionText} theme={"Secondary"} onClick={() => onClickRedirect(props.url)} ></Button>
            </div>
        </ContainerLayout>
    );
}
/****************** OK *******************/