import './PopupContainer.css';
import { useGlobalPopup } from '../../Hooks/useGlobalPopup';
import { ContainerLayout } from '../../../../Layouts/Container/ContainerLayout';

interface IPopupContainerProps{
    children: React.ReactNode;
}

export function  PopupContainer(props: IPopupContainerProps) {

    const {setShowPopup} = useGlobalPopup();

    return (
        <div className="popup-full-screen" onClick={() => setShowPopup(false)}>
            <div className="popup" onClick={(e) => {e.stopPropagation();}}>
                <ContainerLayout className="popup-layout">
                    {props.children}
                </ContainerLayout>
            </div>
        </div>
    )
}