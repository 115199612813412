import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { IdentityHelper, UserClaims } from '../Identity.Helper';
import { IdentityProvider } from "../IdentityProvider";
import {useEffect, useState} from 'react';

export function useIdentityUserEmail() {
    const [userEmail, setUserEmail] = useState<string | null>(null);
    const [userEmailIsLoading, setUserEmailIsLoading] = useState(false);
    const [userEmailError, setUserEmailError] = useState(null);

    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();


    useEffect(() => {
        setUserEmailIsLoading(true);


        if(isAuthenticated){
            const activeAccount = instance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
            const accounts = instance.getAllAccounts();

            const request = {
             ...IdentityProvider.getBaseLoginRequest(),
              account: activeAccount || accounts[0]
            };

            instance.acquireTokenSilent(request)
            .then(authResult => {
                setUserEmail(IdentityHelper.getUserEmailFromClaims(authResult.idTokenClaims as UserClaims))
                setUserEmailIsLoading(false);
                setUserEmailError(null);
            })
            .catch((e) => {
                setUserEmail(null);
                setUserEmailIsLoading(false);
                setUserEmailError(e);
            })
        } else {
            setUserEmail(null);
            setUserEmailIsLoading(false);
            setUserEmailError(null);
        }
    }, [isAuthenticated]);


    return {userEmail, userEmailIsLoading, userEmailError};
}
