import { AppConfiguration } from '../../Services/Configuration/AppConfiguration';


export class IdentityURLs {

    static urlsRelativePaths ={
        DIRECT_IDENTITY_LOGIN: "Identity/SignIn",
        DIRECT_IDENTITY_LOGOUT: "Identity/SignOut",
        DIRECT_IDENTITY_SIGNUP: "Identity/SignUp",
        DIRECT_IDENTITY_EDITPROFILE : "Identity/EditProfile",
        DIRECT_IDENTITY_RESETPASSWORD : "Identity/ForgottenPassword",
    }


    static loginUrl() {
        return AppConfiguration.baseUrl() + IdentityURLs.loginRelativeUrl();
    }

    static logoutUrl() {
        return AppConfiguration.baseUrl() + IdentityURLs.logoutRelativeUrl();
    }

    static signupUrl() {
        return AppConfiguration.baseUrl() + IdentityURLs.signupRelativeUrl();
    }

    static editprofileUrl() {
        return AppConfiguration.baseUrl() +  IdentityURLs.editprofileRelativeUrl();
    }

    static resetpasswordUrl() {
        return AppConfiguration.baseUrl() + IdentityURLs.resetpasswordRelativeUrl();
    }

    static redirectUri() {
        return AppConfiguration.baseUrl();
    }



    static signupRelativeUrl() {
        return IdentityURLs.urlsRelativePaths.DIRECT_IDENTITY_SIGNUP;
    }

    static loginRelativeUrl() {
        return IdentityURLs.urlsRelativePaths.DIRECT_IDENTITY_LOGIN;
    }

    static logoutRelativeUrl() {
        return IdentityURLs.urlsRelativePaths.DIRECT_IDENTITY_LOGOUT;
    }

    static editprofileRelativeUrl() {
        return IdentityURLs.urlsRelativePaths.DIRECT_IDENTITY_EDITPROFILE;
    }

    static resetpasswordRelativeUrl() {
        return IdentityURLs.urlsRelativePaths.DIRECT_IDENTITY_RESETPASSWORD;
    }


}
