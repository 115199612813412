
import { RetailerAccessPutRequestDTO } from '../Models/API/Clients/RetailerAccessPutRequestDTO';
import { RetailerAccessPutResponseDTO } from '../Models/API/Clients/RetailerAccessPutResponseDTO';
import { RetailerAccessDTO, RetailerSearchResponseDTO } from '../Models/API/Clients/RetailerSearchResponseDTO';
import { RetailerDTO, RetailersResponseDTO } from '../Models/API/Clients/RetailersResponseDTO';
import { AccessDTO } from '../Models/Domain/AccessDTO';
import { Retailer } from '../Models/Domain/Retailer';
import { RetailerAccessesTableDTO } from '../Models/Domain/RetailerAccessesTableDTO';
import { IRetailerFormData } from '../Modules/Providers/RetailerForm/RetailerForm';
import { RetailersAccessesSearchRequestDTO } from '../Models/API/Clients/RetailersAccessesSearchRequestDTO';
import { HttpClient } from './Http/HttpClient';
import { AppConfiguration } from './Configuration/AppConfiguration';
import { Access } from '../Models/Domain/Access';

export class RetailerAccessesService {

    getRetailersAccesses(request: RetailersAccessesSearchRequestDTO): Promise<RetailerAccessesTableDTO>{
        return HttpClient.sessionRequest<RetailerSearchResponseDTO>({
            method: "get",
            url: AppConfiguration.backendUrl() + "api/v1/Client/Accesses",
            params: request
        }).then((res) => {

            let responseBody = res.data;

            let retailers = responseBody.retailers.map((p: RetailerAccessDTO) : AccessDTO => ({
                userName: p.username,
                retailerName:p.retailerName,
                state: p.state,
                retailerId: p.retailerId,
            }))

            return ({
                total: responseBody.totalRetailers,
                rows: retailers
            });
        });
    }

    getAllRetailersAccesses(): Promise<AccessDTO[]>{
        return HttpClient.sessionRequest<RetailerSearchResponseDTO>({
            method: "get",
            url: AppConfiguration.backendUrl() + `api/v1/Client/allAccesses`,
        }).then((res) => {

            let responseBody = res.data;

            let retailerAccessesList = responseBody.retailers.map((r: RetailerAccessDTO ) : AccessDTO => ({
                retailerId: r.retailerId,
                userName: r.username,
                password: r.password,
                state: r.state,
                retailerName: r.retailerName
            }));

            return retailerAccessesList;
        }).catch(err => {
            console.log(err);
            return [];
        });
    }

    /**
     * Route: PUT api/v1/Clients/:clientId/accesses
     * APIRequest: RetailerAccessPutRequestDTO
     * APIResponse: RetailerAccessPutResponseDTO
     *
     * @param clientId: number
     * @param retailerAccessToUpdate: AccessDTO
     * @returns AccessDTO
     */
    putRetailersAccesse(retailerAccessToUpdate: IRetailerFormData): Promise<AccessDTO>{
        var requestDTO: RetailerAccessPutRequestDTO = {
            retailerId: retailerAccessToUpdate.retailerId,
            username: retailerAccessToUpdate.userName,
            password: retailerAccessToUpdate.password,
        };

        return HttpClient.sessionRequest<RetailerAccessPutResponseDTO>({
            method: "put",
            url: `${AppConfiguration.backendUrl()}api/v1/Client/Accesses`,
            data: requestDTO
        }).then((res) => {

            let p = res.data;

            let retailer : AccessDTO ={
                userName: p.username,
                password: p.password,
                retailerName:p.retailerName,
                state: p.state,
                retailerId: p.retailerId,
            };

            return retailer;
        });
    }

    /**
     * Route: PUT api/v1/Clients/:clientId/accesses
     * APIRequest: RetailerAccessPutRequestDTO
     * APIResponse: RetailerAccessPutResponseDTO
     *
     * @returns RetailersDTO
     */
    getAllRetailers(): Promise<Retailer[]>{
        return HttpClient.sessionRequest<RetailersResponseDTO>({
            method: "get",
            url: AppConfiguration.backendUrl() + "api/v1/Retailers",
        }).then((res) => {

            let responseBody = res.data;

            let retailers = responseBody.retailers.map((p: RetailerDTO) : Retailer => ({
                id: p.retailerId,
                name: p.retailerName,
                status: p.status
            }))

            return retailers;

        });
    }


    getAllFunctionalRetailers(): Promise<Retailer[]>{
        return HttpClient.sessionRequest<RetailersResponseDTO>({
            method: "get",
            url: AppConfiguration.backendUrl() + "api/v1/Retailers/FunctionalRetailers",
        }).then((res) => {
            let responseBody = res.data;
            let retailers = responseBody.retailers.map((p: RetailerDTO) : Retailer => ({
                id: p.retailerId,
                name: p.retailerName,
                status: p.status
            }))
            return retailers;
        });
    }


    deleteRetailerAccess(retailerAccessId:number): Promise<AccessDTO>{
        return HttpClient.sessionRequest<AccessDTO>({
            method: "DELETE",
            url: `${AppConfiguration.backendUrl()}api/v1/Client/Retailers/${retailerAccessId}`,
        }).then(res => res.data);
    }


    createRetailerAccess(retailerAccess: IRetailerFormData){
        return HttpClient.sessionRequest({
            method: "POST",
            url: `${AppConfiguration.backendUrl()}api/v1/Client/RetailerAccess`,
            data: retailerAccess
        })
       .then((res) => {
            let p = res.data;
            let retailer : AccessDTO ={
                userName: p.username,
                password: p.password,
                retailerName:p.retailerName,
                state: p.state,
                retailerId: p.retailerId,
            };
            return retailer;
        })
    }
}
