import React from 'react';
import { Dialog, DialogClassKey, ThemeProvider } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import "./PopUp.css"

interface IProps {
  nodeInfo?: Info
  onClose:()=>void
  css?: Partial<Record<DialogClassKey, string>>;
  paperClass?: string;
  rootClass?: string;
  theme?: Theme;
}

export interface Info {
  title?: string,
  discription?: string,
  button?: string
}

interface IState {
  open: boolean
}

export const theme = createMuiTheme({
  zIndex: { modal: 99 }
});


export default class AlertDialog extends React.Component<IProps, IState>  {

  render() {
    return (
    <ThemeProvider theme={this.props.theme ?? theme}>
        <Dialog
          classes={{paper: this.props.paperClass, root: this.props.rootClass}}
          open={true}
          onClose={() => this.props.onClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEnforceFocus
        >
          {this.props.children}
          {/* <DialogTitle id="alert-dialog-title">{this.props.nodeInfo.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.nodeInfo.discription}
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button onClick={() => this.handleClose()} color="primary" autoFocus>
              {this.props.nodeInfo.button}

            </Button>
          </DialogActions> */}
        </Dialog>
        </ThemeProvider>
    );
  }
}