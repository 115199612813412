import "./Style.css";
import "@fontsource/open-sans";

interface IButton{
    name: string;
    theme?: 'Gold'|'BorderGold'|'Black' | 'Second-Black' | 'Green' | 'Secondary';
    onClick: () => void;
    small?:boolean;
    className?:string;
    icon?: any,
}

export default function ButtonContained(props: IButton) {

    return (
            <div className={`button${props.small?'-Small':''}  button-${props.theme||'Secondary'} ${props.className || ""} button-${props.theme||'Gold'} ${props.className || ""} button-${props.theme||'Black'} ${props.className} button-${props.theme||'Second-Black'} ${props.className}
            button-${props.theme||'Green'} ${props.className}`}  onClick={props.onClick}>
                <div className="button-Labels">
                    {props.icon}
                    {props.name} 
                </div>
            </div>
    );
}