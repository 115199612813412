import './AllPopUps.css'
import { IconButton } from '../../Atoms/IconButton/IconButton';
import { ReactComponent as CloseSVG } from '../../../Assets/svgs/close.svg'

interface IProps{
    title: string;
    message: string;
    onClose: () => void;
}

export function SuccessPopUp(props: IProps){
    return(
        <div className="popup-container">
            <div className="popup-title">{props.title}</div>
            <div className="popup-separator"/>

            <div className="popup-message">{props.message}</div>

            <div className="popup-buttons">
                <div className="popup-div-button"><IconButton className="btn-gray" text={"Fechar"} icon={<CloseSVG/>} onClick={() => props.onClose()} ></IconButton></div>
            </div>
        </div>
    );
}