import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router';
import { useCallback } from 'react';
import "./Sidebar.css";

interface ISidebarButtonProps {
    iconSVG: any;
    text: string;
    url: string;
}

export function SidebarButton(props: ISidebarButtonProps) {
    let history = useHistory();
   
    const handleClick = useCallback((path: string) => {
        history.push(path);
    },[]);

    return (
        <NavLink to={props.url} activeClassName="sidebar-btn-active" exact={true}>
            <div className="sidebar-btn" onClick={() => handleClick(props.url)}>
                <div className="sidebar-btn-icon">{props.iconSVG}</div>
                <div className="sidebar-btn-text">{props.text}</div>
            </div>
        </NavLink>
    );
}

/****************** OK *******************/