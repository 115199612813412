import { useIsAuthenticated } from "@azure/msal-react";
import { useCallback } from "react";
import { VerifyRequestDTO } from "../../../Models/VerifyRequestDTO";
import { AdminService } from "../../../Services/Http/AdminService";
import { useIdentityUserEmail } from "./useIdentityUserEmail";

export function usePublicGuard() {
    const isAuthenticated = useIsAuthenticated();


    const publicGuard = useCallback(async (to : any, from : any, next : any) => {

    console.log('Public guard', {from, to});
    if(isAuthenticated) {
    next();
    }else{
    console.log("Public guard :: Remove Auth");
    next();
    }

   }, [isAuthenticated] );

   return publicGuard;
}


export function usePrivateGuard() {
    const isAuthenticated = useIsAuthenticated();
    const adminService = new AdminService();

    const privateGuard = useCallback(async (to : any, from : any, next : any) => {

        console.log('Private guard', {from, to});
        if (!isAuthenticated ) {
          console.log("Private guard :: Not Authenticated");
          next.redirect('/Identity/SignIn'); //todo get back to the same page
        }
        else {
          try{
            
        
            let verify = await adminService.verify();
            let hasClientId = !!verify.clientId;
            let isEnteringSubscription = to.location.pathname.toLocaleLowerCase() === '/app/subscription';

            console.log("Verify:: ",{hasClientId, isEnteringSubscription})
          
        
            if(hasClientId || isEnteringSubscription){
              next();
            } else {
              next.redirect("/app/Subscription");
            }
        
          
          } catch(err) {
            //TODO::
            next.redirect("/error/serviceunavailable");
          }
         
          
        };

      }, [isAuthenticated]);
   return privateGuard;
}
