import React, { useState } from 'react'
import './SplashScreen.css'

import logo from './../../../Assets/logos/tyrevalue-multi-dark.png'

export interface ISplashScreenProps{
	text?: string;
}

export function SplashScreen(props: ISplashScreenProps){
	return(
		<div className="splash-bg">
			<div className="splash-spacer" />
			<div className="splash-logo">
			    <img src={logo} className="splash-logo-product" />
			</div>
			<div className="splash-spacer" />
		</div>
	);
}
