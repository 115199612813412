import { useCallback } from 'react';
import Button from "../../../Components/Buttons/Button";
import { useState } from "react";
import { SearchByDimension } from '../../../Components/Molecules/SearchEngine/SearchByDimension';
import { SearchByVehicle } from '../../../Components/Molecules/SearchEngine/SearchByVehicle';
import { ConditionalRender } from '../../../Components/Directives/ConditionalRender';
import { ContainerLayout } from '../../../Layouts/Container/ContainerLayout';
import "./SearchCard.css";

enum SearchTab {
    DIMENSION,
    PLATE
}

interface ISearchCardProps {
    setReference: (ref: string) => void; //TODO: CHANGE THIS TO ANOTHER WAY
}

export function SearchCard(props: ISearchCardProps) {
    const [selectedTab, setSelectedTab] = useState<SearchTab>(SearchTab.DIMENSION);

    const getCssForTab = useCallback((tab: SearchTab) => {
        if(selectedTab === tab){
            return "btn-active"
        }
        return "";
    }, [selectedTab])

    return (
        <ContainerLayout className="searchcard">
             <div className="searchCard-content">
                <div className="searchCard-header">
                    <div className="searchCard-title">Pesquisa</div>
                    <div className='searchCard-buttonGroup'>
                        <Button name={"Dimensão"} className={getCssForTab(SearchTab.DIMENSION)} theme={"Black"} onClick={() => {setSelectedTab(SearchTab.DIMENSION)}} ></Button>
                        <Button name={"Veículo"}  className={getCssForTab(SearchTab.PLATE)}     theme={"Black"} onClick={() => {setSelectedTab(SearchTab.PLATE)}} ></Button>
                    </div>
                </div>
                <div className="renderSearchSelected">
                    <ConditionalRender if={selectedTab === SearchTab.PLATE}><SearchByVehicle setReference={props.setReference}/></ConditionalRender>
                    <ConditionalRender if={selectedTab === SearchTab.DIMENSION}><SearchByDimension setReference={props.setReference}/></ConditionalRender>
                </div>
            </div>
        </ContainerLayout>
    );
}

/****************** OK *******************/