import React from "react";
import "./PopUp.css"
import { Theme } from "@material-ui/core";
import AlertDialog from './AlertDialog';
import Scrollable from './Scrollable';
import DefaultLayout from './DefaultLayout';

interface IProp {
    title?: string,
    onClose: Function,
    rootClass?: string,
    paperClass?: string,
    height?: number,
    theme?: Theme;
}



export default class PopUpContentBox extends React.Component<IProp> {

    render() {
        return (
            <AlertDialog onClose={() => this.props.onClose()} paperClass={this.props.paperClass} rootClass={this.props.rootClass} theme={this.props.theme}>
                
                    <DefaultLayout>
                        {this.props.children}
                    </DefaultLayout>
                
            </AlertDialog>
        );
    }
}