import { ReactComponent as PencilSVG } from "../../../Assets/svgs/pencil.svg";
import { ConditionalRender } from '../../Directives/ConditionalRender';
import PopUp from "../../PopUp/PopUp";
import { RetailerForm, IRetailerFormData } from '../../../Modules/Providers/RetailerForm/RetailerForm';
import { Retailer } from '../../../Models/Domain/Retailer';
import { AccessDTO } from '../../../Models/Domain/AccessDTO';
import "./AllPopUps.css"

interface IProps {
    showEditPopUp: boolean;
    allRetailers: Retailer[];
    setShowEditPopUp: (state: boolean) => void;
    onEdit: (formData: IRetailerFormData) => void;
    selectedAccess?: AccessDTO;
}

export function EditRetailerAccess(props: IProps){

    return (
        <ConditionalRender if={props.showEditPopUp}>
            <PopUp onClose={() => { props.setShowEditPopUp(false)}}>
                <div className="popup-container">
                    <div className="popup-title">Editar Acesso</div>
                    <div className="popup-separator"/>
                
                    <RetailerForm 
                        retailersList={props.allRetailers}
                        onCompleted={props.onEdit}
                        value={props.selectedAccess}
                        userText={"Utilizador"}
                        passText={"Palavra-Passe"}
                        theme={"btn-gray"}
                        icon={<PencilSVG/>}
                        buttonText={"Editar"}
                    />
                </div>
            </PopUp>
        </ConditionalRender>
    );
}