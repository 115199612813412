import React from "react";
import { IdentityApp } from './Identity/Components/IdentityApp';
import { UIServices } from "./UI/UIServices";

interface ISPAAppProps {
    children: React.ReactNode;
}

export function SPAApp(props: ISPAAppProps){
    return (
        <IdentityApp>
            <UIServices>
                {props.children}
            </UIServices>
        </IdentityApp>
    )
}