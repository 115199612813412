import React, { useCallback, useEffect, useState } from 'react';
import { TableRow } from './TableRow/TableRow';
import { TableHeaderCell } from './TableHeaderCell/TableHeaderCell';

import './Table.css';
import { TableFooter } from './TableFooter/TableFooter';
import { ConditionalRender } from '../../Directives/ConditionalRender';
import CircularProgress from '@material-ui/core/CircularProgress';

export interface ITableColumn<T> {
    key: string;
    headerLabel: string | any;
    sortable: boolean;
    valueTransform?: (value: T) => React.ReactNode;
    cellClassNames?: string[];
    isHidden?: boolean;
}

export interface IKeyLabel{
    key: number
    label: number
}

export interface    OptionOrder{
    key: number;
    label: boolean;
}

interface ITableProps<T> {
    dataList: T[];
    dataTotalItems: number;
    columnDefinition: ITableColumn<T>[];
    paginationOptions?: IKeyLabel[];
    currentPage?: number;
    itemsPage?: number;
    isLoading?: boolean;
    onClickSearch?: boolean;
    className?: string;
    onCurrentPageChanged?: (newPage: number) => void;
    onRowsPerPageChange?: (rowsPerPage: number) => void;
    onOrderChange?: (orderCol:string, orderDir: 'ASC' | 'DESC') => void;
}

const columns = ["Reference","Desc", "Retailer.RetailerName", "Brand", "State", "Stock", "PriceNet"]

export function Table<T>(props: ITableProps<T>){
""
    const [orderActive, setOrderActive] = useState<boolean>(false);
    const [allTableCells, setAllTableCells] = useState<OptionOrder[]>();

    const [currentOrderColumnIdx, setCurrentOrderColumnIdx] = useState<number>(-1);
    const [currentOrderDirection, setCurrentOrderDirection] = useState<'ASC' | 'DESC'>('ASC');
    
    useEffect(( ) => {
    },[props.onClickSearch])

    const handleValidateColumns = useCallback((col: string, dir: 'ASC' | 'DESC') => {
        columns.forEach((c) => {
            if(col == c && props.onOrderChange){
                props.onOrderChange(col, dir);
            }
            // else if(props.onOrderChange){
            //     props.onOrderChange("", dir);
            // }
        })
    },[props])
    
    return (
        <div className={"n4mp-table " + (props.className || "")}>
            <ConditionalRender if={props.isLoading}><div className="n4mp-table-overlay"><CircularProgress disableShrink size="45px"/></div></ConditionalRender>
            <table>
                <thead>
                    <tr >
                        {props.columnDefinition.filter(r => !(r.isHidden)).map((col, idx) =>  <TableHeaderCell 
                        columnId={idx}
                        orderInfo={{isActive: props.onClickSearch ? false : currentOrderColumnIdx == idx, direction: currentOrderDirection}}
                            onOrderClick={(dir, colIdx) => {
                                setCurrentOrderColumnIdx(colIdx);
                                setCurrentOrderDirection(dir);
                                if(props.onOrderChange) {
                                    handleValidateColumns(col.key, dir)
                                    //props.onOrderChange(col.key, dir);
                                }
                            }}
                        column={col}/>)}
                    </tr>
                </thead>
                <tbody>
                    {props.dataList.map((row, ridx) =>
                        <TableRow key={ridx} item={row} columnDefinitions={props.columnDefinition.filter(r => !(r.isHidden))}/>)
                    }
                    {props.dataList.length < 1
                    ? 
                        <td colSpan={props.columnDefinition.filter(r => !(r.isHidden)).length}>
                            <div className="n4mp-table-info">Sem Dados</div>
                        </td>
                    : 
                        null}
                </tbody>

                <ConditionalRender if={props.paginationOptions && !!props.paginationOptions.length}>
                    <tfoot>
                        <td className="n4mp-table-cell-reset" colSpan={props.columnDefinition.filter(r => !(r.isHidden)).length}>
                            <TableFooter
                                totalItems={props.dataTotalItems} //total pneus
                                paginationOptions={props.paginationOptions || []} //opções tamanho da tabela
                                currentPage={props.currentPage || 0}
                                currentPageItemCount={props.dataList.length} // total dos pneus
                                itemsPage={props.itemsPage} // tamanho da pagina da tabela
                                onCurrentPageChanged={props.onCurrentPageChanged}
                                onRowsPerPageChanged={props.onRowsPerPageChange}
                            />
                        </td>
                    </tfoot>
                </ConditionalRender>
            </table>
        </div>
    );
}