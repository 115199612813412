import { BudgetPostRequestDTO } from '../Models/API/Clients/BudgetPostRequestDTO';
import { AppConfiguration } from './Configuration/AppConfiguration';
import { HttpClient } from './Http/HttpClient';
import { BudgetPostResponseDTO, BudgetDTO } from '../Models/API/Clients/BudgetPostResponseDTO';
import { BudgetsResponseDTO } from '../Models/API/Clients/BudgetsReponseDTO';
import {BudgetSearchRequestDTO} from '../Models/API/Clients/BudgetSearchRequestDTO'


export class BudgetService{
    postBudget(request: BudgetPostRequestDTO, userEmail:string): Promise<BudgetPostResponseDTO>{
        
        return HttpClient.sessionRequest<BudgetPostResponseDTO>({
            method: "post",
            url: `${AppConfiguration.backendUrl()}api/v1/Budget/${userEmail}`,
            data: request
        }).then((res) => {
            return ({
                clientId: res.data.clientId,
                budget: res.data.budget,
            });
        });
    }

    getAllClientBudgets(request: BudgetSearchRequestDTO ): Promise<BudgetsResponseDTO>{
        return HttpClient.sessionRequest<BudgetsResponseDTO>({
            method: "get",
            url: `${AppConfiguration.backendUrl()}api/v1/Budget`,
            data: request
        }).then((res) => {
            return ({
               total: res.data.total,
               budgets: res.data.budgets
            });
        });
    }

    deleteBudget(budgetId: number): Promise<BudgetDTO>{
        return HttpClient.sessionRequest<BudgetDTO>({
            method: "delete",
            url: `${AppConfiguration.backendUrl()}api/v1/Budget/DeleteOneBudget/${budgetId}`
        }).then(res => (res.data))
    }
}