import { AccountInfo, AuthenticationResult, EndSessionRequest, EventType, InteractionStatus, IPublicClientApplication, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { IdentityHelper, UserClaims } from "./Identity.Helper";
import { AppConfiguration } from '../../Services/Configuration/AppConfiguration';
import { IdentityProvider } from './IdentityProvider';
import { IdentityURLs } from './IdentityUrls';




export class IdentityService {


    static Global: IdentityService;
    protected static isAuthenticated: boolean = false;
    protected static inProgress: InteractionStatus;


    static initInstance(msalInstance: PublicClientApplication){

        if(!msalInstance){
            throw "Invalid MSAL Client";
        }

        IdentityService.Global = new IdentityService(msalInstance);

        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
        }

        msalInstance.addEventCallback((event) => {

            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                msalInstance.setActiveAccount(account);
            }

            //console.log(message);
            if(event.error){

                console.log("MSAL Error", event);
            }
            //AADB2C90118: The user has forgotten their password.
            if(IdentityHelper.errorMessageContains(event, 'AADB2C90118')){
                //IdentityService.Global.forgottenPassword();
                window.location.href = IdentityURLs.resetpasswordUrl();
            }
        });
    }



    static setAuthenticated(isAuthenticated: boolean){
        IdentityService.isAuthenticated = isAuthenticated;
        if(!isAuthenticated){
            IdentityService.Global.saveClaims(null);
        }
    }

    static setInProgress(progress: InteractionStatus){
        IdentityService.inProgress = progress;
    }



    /** -------------------------------------------------------------
     *
     *  Identity Service With MSAL
     *
     *  ------------------------------------------------------------- */

        msalInstance: IPublicClientApplication;

        constructor(instance: IPublicClientApplication){
            this.msalInstance = instance;
        }

        login() {
            let request: RedirectRequest = {
                ...IdentityProvider.getBaseLoginRequest(),
                redirectStartPage: AppConfiguration.baseUrl() + "app/home",

                redirectUri: IdentityURLs.redirectUri(),

            };
            return this.msalInstance.loginRedirect(request);
        }

        logout  () {
            this.saveClaims(null);
            let request: EndSessionRequest = {
                postLogoutRedirectUri: IdentityURLs.redirectUri(),
            }
            return this.msalInstance.logoutRedirect(request);
        }

        signUp() {
            let request: RedirectRequest = {
                ...IdentityProvider.getBaseLoginRequest(),
                authority: IdentityProvider.getB2CPolicies().authorities.signUp.authority,
                redirectStartPage: AppConfiguration.baseUrl(),
                redirectUri: IdentityURLs.redirectUri(),
            }
            return this.msalInstance.loginRedirect(request);
        }

        forgottenPassword() {
            let request: RedirectRequest = {
                ...IdentityProvider.getBaseLoginRequest(),
                redirectUri: IdentityURLs.redirectUri(),
                redirectStartPage:  IdentityURLs.redirectUri(),
                authority: IdentityProvider.getB2CPolicies().authorities.forgotPassword.authority,
            }
            return this.msalInstance.loginRedirect(request);
        }

        loginBackToUrl(url: string){
            let request: RedirectRequest = {
                ...IdentityProvider.getBaseLoginRequest(),
                redirectUri: IdentityURLs.redirectUri(),
                redirectStartPage: url,
            }
            return this.msalInstance.loginRedirect(request);
        }


        editProfileBackToUrl(url: string){
            let request: RedirectRequest = {
                ...IdentityProvider.getBaseLoginRequest(),
                redirectUri: IdentityURLs.redirectUri(),
                authority: IdentityProvider.getB2CPolicies().authorities.editProfile.authority,
                redirectStartPage: url,
            }
            return this.msalInstance.loginRedirect(request);
        }


        async getAuthenticationResult(): Promise<AuthenticationResult | void | null> {


            const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
            const accounts = this.msalInstance.getAllAccounts();


            if(IdentityService.inProgress !== InteractionStatus.None ) {
                var res = await this.msalInstance.handleRedirectPromise();
                if(res){
                    console.log("Handle Promise", res);
                    return res;
                }

            }

            const request = {
             ...IdentityProvider.getBaseLoginRequest(),
              account: activeAccount || accounts[0]
            };

            const redirectRequest: RedirectRequest = {
              ...IdentityProvider.getBaseLoginRequest(),
              account: activeAccount || accounts[0]
            }


            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            return this.msalInstance
            .acquireTokenSilent(request)
            .then(authResult => {
                this.saveClaims(authResult.idTokenClaims as UserClaims);
                return authResult;
            })
            .catch((e) => {
              console.log("Aquire Redirect", e); //TODO: redirect back to the same page???
                return this.msalInstance
                .acquireTokenRedirect(redirectRequest)
                .catch((e) => {
                    return null;
                  });;

            });

        }

        //Hearbeat purposes
        tryAquireAccessToken(): Promise<string | null>{
            const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
            const accounts = this.msalInstance.getAllAccounts();

            if(IdentityService.inProgress !== InteractionStatus.None ) {
                return this.msalInstance.handleRedirectPromise().then(authResult => {

                    if (authResult && authResult as AuthenticationResult){
                        this.saveClaims(authResult.idTokenClaims as UserClaims);
                        return authResult.accessToken;
                    }
                    return null;
                })
                .catch((e) => {
                  return null;
                });;
            }


            const request = {
             ...IdentityProvider.getBaseLoginRequest(),
              account: activeAccount || accounts[0]
            };

            const redirectRequest: RedirectRequest = {
              ...IdentityProvider.getBaseLoginRequest(),
              account: activeAccount || accounts[0]
            }


            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            return this.msalInstance
            .acquireTokenSilent(request)
            .then(authResult => {
                this.saveClaims(authResult.idTokenClaims as UserClaims);
                if (authResult && authResult as AuthenticationResult){
                    return authResult.accessToken;
                }
                return null;
            })
            .catch((e) => {
              return null;
            });
        }


        getAccessToken(): Promise<string | void> {
            return this.getAuthenticationResult()
            .then(result => {

              if (result && result as AuthenticationResult){
                return result.accessToken;
              }
            })
        }



        static storageKey = "MARKETPLACE_STORAGE_IDENTITY_CLAIMS";

        saveClaims(claims: UserClaims | null){
            if (claims) {
                window.localStorage.setItem(IdentityService.storageKey, JSON.stringify(claims));
              } else {
                window.localStorage.removeItem(IdentityService.storageKey);
              }
        }

        getClaims(): UserClaims{
            let claims = window.localStorage.getItem(IdentityService.storageKey);
            return claims && JSON.parse(claims);
        }
}
