import { useEffect, useMemo, useState } from 'react';
import './Checkbox.css'

interface ICheckBoxProps {
    onToogle: (isActive: boolean) => void;
    isChecked: boolean;
    text?: string;
    className?: string;
}

export function Checkbox(props: ICheckBoxProps){

    const [isActive, setIsActive] = useState(props.isChecked || false);

    useEffect(() => {
        console.log(isActive)
        props.onToogle(isActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive])

    useEffect(() => {
        setIsActive(props.isChecked);
    }, [props.isChecked])

    const cssClasses = useMemo(() => {
        let csss = ["text"];
        if(props.className){
            csss.push(props.className);
        }
        return csss.join("-");

    }, [props.className])

    return(
        <div className='checkbox'>
            <label className="checkbox-container">
                <input type="checkbox" checked={isActive} onClick={(e) => setIsActive(!isActive)}/>
                <span className="checkmark"></span>
                <span className={cssClasses}>{props?.text}</span>
            </label>
        </div>
    );
}
