import { ThreeSixtySharp } from "@material-ui/icons";
import React from "react";
import { IconButton } from "../../../Components/Atoms/IconButton/IconButton";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import '../SubscriptionPage.css';


interface IProp {
    setValue: (value: string) => void;
    errorMessage?: string;
    onClick:React.MouseEventHandler<HTMLDivElement>;
    title?: string;
    subTitle?: string;
    theme?: string;
    iconText?: string;
    placeholder?: string;
    isInvitationForm?: boolean;
    icon?: React.ReactNode;
}

export class SubscriptionForm extends React.Component<IProp> {

    render() {
        return (
                
            <ContentBox overrideStyle={true} theme={"White"} contentClassName="subForm-contentbox">  

                <div className="subForm-container">
                    <div className="subForm-title">{this.props.title}</div>
                    <div className="popup-separator"/>

                    {this.props.children ?
                    <div className="data-title">
                    {this.props.children}
                    </div>    
                    :
                    null
                    }

                    { this.props.isInvitationForm ?
                    <div className="retailerForm-data-container">
                        <div className="data-title">{this.props.subTitle}</div>
                        <input type="text" className="input-invite"  onChange={(e) => this.props.setValue(e.target.value)} placeholder={this.props.placeholder}></input>
                        {this.props.errorMessage
                            ? <h5 className="error-message">{this.props.errorMessage}</h5>
                            : null
                        }  
                    </div>
                    : 
                    null
                    }
                    <div className='sub-form'></div>
                    <div className='sub-button'>
                        <IconButton className={this.props.theme} text={this.props.iconText} onClick={this.props.onClick}></IconButton>
                    </div>
                </div>
                    
                
            </ContentBox>
        );
        }
    }