import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../Assets/svgs/menu-down.svg'
import { ReactComponent as ArrowUp } from '../../Assets/svgs/menu-up.svg'
import './ComboBox.css'


export interface CbOption{
    key: number;
    label: string;
}


interface IProps{
    optionsList: CbOption[];
    onChange: (selected: any) => void;
    selectedOp?: string;
    css?: string;
    placeholder: string;
}


export function ComboBox(props: IProps){
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [options, setOptions] = useState<CbOption[]>([])
    const [selectedOp, setSelectedOp] = useState<string>()
    const [optionsFiltered, setOptionsFiltered] = useState<CbOption[]>([])


    const handleSelected = useCallback((op: CbOption) => {
        setSelectedOp(op.label)
        props.onChange(op.label)
    },[setSelectedOp, props])


    useEffect(() => {
        setOptions(props.optionsList)
        setOptionsFiltered(props.optionsList)
    },[props])

    useEffect(() => {
        if(props.selectedOp !== selectedOp){
            setSelectedOp(props.selectedOp);
        }
    }, [props.selectedOp])


    return(
        <div className="combobox-container" onClick={() => setIsOpen(!isOpen)} onMouseLeave={() => setIsOpen(false)}>
            <div className="combobox">
                <div className={"combobox-group" + props.css}>
                    <div className="combobox-text">{selectedOp || "Estado"}</div>
                    <div className="combobox-arrow">
                        {!isOpen
                        ?
                            <ArrowDown className="svg"/>
                        :
                            <ArrowUp className="svg"/>
                        }
                    </div>
                </div>
            </div>
            {isOpen
            ?
                <div className="combobox-dropdown">
                    {optionsFiltered.map((op, idx) => {
                        return <div key={idx} className="combobox-option" onClick={() =>{handleSelected(op); setIsOpen(false)}}>{op.label}</div>
                    })}
                </div>
            :
                null
            }
        </div>
    );
}