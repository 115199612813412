import React from 'react';
import './App.css';
import { Route, Switch} from 'react-router-dom';
import { AppIdentityModule } from './App.Identity.Module';
import { AppMainModule } from './App.Main.Module';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

import { IdentityService } from './Infrastructure/Identity/IdentityService';
import { SplashScreen } from './Modules/Common/SplashScreen/SplashScreen';
import { InteractionStatus } from '@azure/msal-browser';


export default function App() {


  const {inProgress} = useMsal();

  useEffect( () => {
    IdentityService.setInProgress(inProgress);
  }, [inProgress]);



  return (

      <div className="main">

    {(inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect)  ?



          <Switch>
              <Route path="/identity"> <AppIdentityModule/> </Route>
              <Route path="/error">error</Route>
              <Route path="*"> <AppMainModule/> </Route>
          </Switch>



    : <SplashScreen></SplashScreen> }




    </div>
  );
}
