
import { ProductSearchRequestDTO } from '../Models/API/Clients/ProductSearchRequestDTO';
import { ProductDTO, ProductSearchResponseDTO } from '../Models/API/Clients/ProductSearchResponseDTO';
import { ProductTableDTO } from '../Models/Domain/ProductTableDTO';
import { TyreDTO } from '../Models/Domain/TyreDTO';
import { RetailerAndProductsSearchResponseDTO } from '../Models/API/Clients/RetailerAndProductsSearchResponseDTO';
import { TotalProductsAndRetailersDTO } from '../Models/Domain/TotalProductsAndRetailersDTO';
import { PriceRangeResponseDTO } from '../Models/API/Clients/PriceRangeResponseDTO';
import { BrandSearchResponseDTO } from '../Models/API/Clients/BrandSearchResponseDTO';
import { ClientDTO } from '../Models/API/Clients/ClientDTO';
import { HttpClient } from './Http/HttpClient';
import { AppConfiguration } from './Configuration/AppConfiguration';
import { UserSettingsDTO } from '../Models/Domain/UserSettingsDTO';

export class ClientService {

    searchProducts(request: ProductSearchRequestDTO): Promise<ProductTableDTO>{
        return HttpClient.sessionRequest<ProductSearchResponseDTO>({
            method: "get",
            url: AppConfiguration.backendUrl() + "api/v1/Client/Products",
            params: request
        }).then((res) => {

            let responseBody = res.data;

            let tyres = responseBody.products.map((p: ProductDTO) : TyreDTO => ({
                completeDescription: p.desc,
                reference: p.reference,
                model: p.model,
                fuelConsumption: p.fuelConsumption,
                grip: p.grip,
                rollingNoise: p.rollingNoise,
                state: p.state,
                icIv: p.icIV,
                retailerName: p.retailerName,
                brand:p.brand,
                link: p.link,
                priceNet: p.priceNet,
                stock: parseInt(p.stock),
            }))

            return ({
                total: responseBody.totalProducts,
                rows: tyres,
                minPrice: responseBody.minPrice,
                maxPrice: responseBody.maxPrice
            });
        });
    }

    getPriceRange(): Promise<PriceRangeResponseDTO>{
        return HttpClient.sessionRequest<PriceRangeResponseDTO>({
            method: "get",
            url: AppConfiguration.backendUrl() + `api/v1/Client/priceRange`,
        }).then((res) => {

            let p = res.data;

            return p;
        });
    };

    getTotalProductsAndRetailers(): Promise<TotalProductsAndRetailersDTO>{
        return HttpClient.sessionRequest<RetailerAndProductsSearchResponseDTO>({
            method: "get",
            url: AppConfiguration.backendUrl() + "api/v1/Client/productsAccesses",
        }).then((res) => {
            return ({
                totalProducts: res.data.totalProducts,
                totalRetailers: res.data.totalRetailers
            });
        });
    }

    getAllClientBrands(): Promise<BrandSearchResponseDTO>{
        return HttpClient.sessionRequest<BrandSearchResponseDTO>({
            method: "get",
            url: AppConfiguration.backendUrl() + "api/v1/Client/allBrands",
        }).then((res) => {
            return ({
                totalBrands: res.data.totalBrands,
                brands: res.data.brands
            });
        });
    }

    getProfitMargin(): Promise<number>{
        return HttpClient.sessionRequest<number>({
            method: "get",
            url: `${AppConfiguration.backendUrl()}api/v1/Client/ProfitMargin`,
        }).then((res) => {
            return res.data;
        });
    };

    putProfitMargin(newProfitMargin: number): Promise<number>{
        return HttpClient.sessionRequest<ClientDTO>({
            method: "put",
            url: `${AppConfiguration.backendUrl()}api/v1/Client/ProfitMargin`,
            data: { margin: newProfitMargin},
        }).then((res) => {
            console.log("###" + res.data.profitMargin)
            return res.data.profitMargin;
        });
    }

    getUserSettings(): Promise<UserSettingsDTO>{
        return HttpClient.sessionRequest<UserSettingsDTO>({
            method: "get",
            url: `${AppConfiguration.backendUrl()}api/v1/Client/Settings`,
        }).then((res) => {
            return res.data;
        });
    };

    putUserSettings(request: UserSettingsDTO): Promise<boolean>{
        return HttpClient.sessionRequest<boolean>({
            method: "put",
            url: `${AppConfiguration.backendUrl()}api/v1/Client/Settings`,
            data: request,
        }).then((res) => {
            return res.data;
        });
    }
}
