import { CircularProgress } from "@material-ui/core";
import './FullScreenLoader.css';

interface IFullScreenLoaderProps {
    showLoader?: boolean;
}

export function FullScreenLoader(props: IFullScreenLoaderProps){
    return (
        <>
            {!props.showLoader
            ? 
                null 
            :
                <div className="fullScreenLoader">
                    <CircularProgress ></CircularProgress>
                </div>
            }
        </>
    );
}