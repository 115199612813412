import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";
import { DropdownMenu } from '../../DropdownMenu/DropdownMenu';
import { useIsAuthenticated } from "@azure/msal-react";
import { useIdentityUserDisplayName } from '../../../Infrastructure/Identity/Hooks/useIdentityUserDisplayName';
import { ReactComponent as UserSvg } from '../../../Assets/svgs/user.svg';
import "./Header.css"

interface IProps{
    title:string;
}

export function Header(props:IProps){
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    let history = useHistory();

    const handleClickUsername = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    },[setAnchorEl]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    },[setAnchorEl]);

    const handleItemClick = useCallback((option: string) => {
        switch(option){
            case "Sair":
                history.push("/Identity/SignOut");
                break;
            case "Definições":
                history.push("/app/home/settings");
                console.log("Definições click -> not yet implemented");
                break;
            default:
                break;
        }
    },[]);

    return(
        <div className="header">
            <div className="header-title">{props.title}</div>
            <div className="header-user n4mp-circle-avatar">
            <div className="userSVG" onClick={(e) => handleClickUsername(e)}>
                    <UserSvg />
                </div>
                <div className="dropDownMenu">
                    <DropdownMenu
                        handleClickUsername={handleClickUsername}
                        handleItemClick={handleItemClick}
                        handleClose={handleClose}
                        anchor={anchorEl}
                    />
                </div>
            </div>
        </div>
    )
}

/****************** OK *******************/