import { ConfigurationProvider } from "../../Infrastructure/Configuration/ConfigurationProvider";


export class AppConfiguration {



    static baseUrl() { return ConfigurationProvider.getConfiguration().App.BaseUrl };



    static backendUrl() {  return ConfigurationProvider.getConfiguration().App.BackendUrl };


    static isDev() { return ConfigurationProvider.getConfiguration().App.IsDev; };

}
