import { useState, useCallback, useEffect } from 'react';
import { Input } from '../../Components/Atoms/Input/Input';
import Button from '../../Components/Buttons/Button';
import { ClientService } from '../../Services/ClientService';
import "./SettingsPage.css";
import { useGlobalPopup } from '../../Infrastructure/UI/Hooks/useGlobalPopup';
import { useGlobalLoading } from '../../Infrastructure/UI/Hooks/useGlobalLoading';
import { SuccessPopUp } from '../../Components/Molecules/PopUp/SuccessPopUp';
import { FullScreenLoader } from '../../Infrastructure/UI/Components/FullScreenLoader/FullScreenLoader';
import { AdminService } from '../../Services/Http/AdminService';
import { Checkbox } from '../SearchPage/Components/Checkbox/Checkbox';
import { UserSettingsDTO } from '../../Models/Domain/UserSettingsDTO';

interface IProps{
    setHeaderTitle: (title: string) => void;
}

export function SettingsPage(props: IProps){
    const [profitMargin, setProfitMargin] = useState<number>(0);
    const [invitationToken, setInvitationToken] = useState<string>("");
    const [profitMarginBackup, setProfitMarginBackup] = useState<number>(0);
    const [finalPrice, setFinalPrice] = useState<boolean>(false);
    const [salePrice, setSalePrice] = useState<boolean>(false);

    //--- Popup e Full Screen Loader --------------------------------------------
    const {setShowPopup, setPopupContent} = useGlobalPopup();
    const {setShowFSL, setFSLContent} = useGlobalLoading();



    const onSaveClick = useCallback(async () => {
        setShowFSL(true)
        setFSLContent(<FullScreenLoader />)

        const clientService = new ClientService();
        try{
            await clientService.putProfitMargin(profitMargin).then(p => {
                setShowFSL(false)
                setShowPopup(true)
                setPopupContent(<SuccessPopUp title={"Margem de Lucro"} message={"Margem de lucro alterada com sucesso!"} onClose={() => setShowPopup(false)} />)
                setProfitMarginBackup(profitMargin)
            })
        }catch(err: any){
            setShowFSL(false)
            setShowPopup(true)
            setPopupContent(<SuccessPopUp title={"Margem de Lucro"} message={"Ocorreu um problema. A margem de lucro não foi alterada!"} onClose={() => setShowPopup(false)} />)
            setProfitMargin(profitMarginBackup)
        }
    },[profitMargin, setShowFSL, setShowPopup, setPopupContent, setFSLContent, setProfitMarginBackup, profitMarginBackup])


    const validateForm = useCallback(() => {
        if(profitMargin){
            onSaveClick()
        }else{
            setShowFSL(false)
            setShowPopup(true)
            setPopupContent(<SuccessPopUp title={"Margem de Lucro"} message={"Por favor introduza a nova margem de lucro!"} onClose={() => setShowPopup(false)} />)
            setProfitMargin(profitMarginBackup)
        }
    },[profitMargin, onSaveClick, setShowFSL, setPopupContent, setShowPopup, setProfitMargin, profitMarginBackup])


    const loadProfitMargin = useCallback(() => {
        const clientService = new ClientService();
        clientService.getProfitMargin().then(c => {
            setProfitMargin(c)
            setProfitMarginBackup(c)
        })
    },[setProfitMargin, setProfitMarginBackup])

    const loadUserSettings = useCallback(() => {
        const clientService = new ClientService();
        clientService.getUserSettings().then(c => {
            setFinalPrice(c.finalPrice);
            setSalePrice(c.salePrice);
        })
    },[setFinalPrice, setSalePrice])

    const putUserSettings = useCallback(() => {

        var request: UserSettingsDTO = {
            finalPrice: finalPrice,
            salePrice: salePrice,
        }
        console.log(request)
        const clientService = new ClientService();
        clientService.putUserSettings(request).then(c => {

        })
    },[finalPrice, salePrice, setFinalPrice, setSalePrice])

    const loadInvitationToken = useCallback(() => {
        const adminService = new AdminService();
        adminService.getInvitationCode().then(c => {
            setInvitationToken(c)
        })
    },[setInvitationToken])
    

    useEffect(() => {
       loadProfitMargin();
       loadUserSettings();
       props.setHeaderTitle("Definições");
    },[])


    return(
        <div className="mainSettings">
            <div className="settings-container">
                <h3>Margem de Lucro</h3>
                <div className="profitMargin">
                    <Input type="text" className="column col-md-12" placeholder={"Margem"} value={profitMargin || ""} 
                           onChange={(e) => setProfitMargin(Number.parseInt(e.target.value))} />
                    <Button name={"Guardar"} theme={"Gold"} onClick={() => validateForm()} ></Button>
                </div>
            </div>
            <div className="settings-container">
                <h3>Código para convite</h3>
                <div className="profitMargin">
                    <Input type="text" className="column col-md-12" placeholder={"Código"} value={invitationToken || ""} />
                    <Button name={"Obter"} theme={"Gold"} onClick={() => loadInvitationToken()} ></Button>
                </div>
            </div>
            <div className="settings-container">
                <h3>Colunas Opcionais</h3>
                <div className="profitMargin">
                    <Checkbox
                        isChecked={finalPrice}
                        text="Preço Venda"
                        onToogle={setFinalPrice}
                    />
                    <Checkbox
                        isChecked={salePrice}
                        text="Preço Final"
                        onToogle={setSalePrice}
                    />
                    <Button name={"Guardar"} theme={"Gold"} onClick={() => putUserSettings()} ></Button>
                </div>
            </div>
        </div>
    )
}
/****************** OK ***************/