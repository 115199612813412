import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../Components/Buttons/Button";
import { IdentityService } from '../../Infrastructure/Identity/IdentityService';
import ContentBox from '../../Components/ContentBox/ContentBox';
import bg from './../../Assets/bg.png'
import logo from './../../Assets/logos/tyrevalue-multi-light.png'
import "./Home.css";

export default function HomePage() {
    let history = useHistory();

    const onClickRedirect = useCallback((path: string) => {
        history.push(path);
    },[history]);

    return(
        <div className="n4it-login">
            <div className="n4it-login-overlay"><img src={bg} alt="overlay"/></div>
            <div className="n4it-login-overlay yellow"></div>

            <div className="n4it-login-content">
                <ContentBox theme={""} defaultPadding={true} >
                    <div className="home-items">
                        <div className="home-title"><img src={logo} className="login-logo-product" alt="logo"/></div>
                        <div className="home-secondTitle">Bem-vindo!</div>
                        <div className={"home-fullWidthHeight"}>
                            <Button name={"Entrar"} theme={"Gold"} className="button-spaced" onClick={() => onClickRedirect("/Identity/SignIn")}/>
                            <Button name={"Registar"} theme={"Secondary"} className="button-spaced" onClick={() => onClickRedirect("/Identity/SignUp")}/>
                            {/*<Link to={"/" + IdentityService.resetpasswordRelativeUrl()}>
                                <p>Recuperar a palavra-passe</p>
                            </Link>*/}
                        </div>
                    </div>
                </ContentBox>
            </div>
        </div>
    )
}
