import { useEffect, useState, useCallback } from 'react';
import './SubscriptionPage.css';
import { useHistory } from 'react-router-dom';
import Button from '../../Components/Buttons/Button';
import ContentBox from '../../Components/ContentBox/ContentBox';
import { FormField } from '../SearchPage/Components/FormField/FormField';
import { Input } from '../../Components/Atoms/Input/Input';
import { IconButton } from '../../Components/Atoms/IconButton/IconButton';
import { SubscriptionForm } from './SubscriptionForm/SubscriptionForm';
import { ReactComponent as Check } from "../../Assets/svgs/check.svg";
import { AdminService } from '../../Services/Http/AdminService';

interface IProps{
    setHeaderTitle: (title: string) => void;
}

export function SubscriptionPage(props: IProps){
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [invitationToken, setInvitationToken] = useState("");
    const history = useHistory();

    useEffect(() => {
        props.setHeaderTitle("Subscrição");
    }, []);

    const onSubmitInvitation = useCallback(() => {
        setIsLoading(true);
        var adminService = new AdminService();
        console.log("cheguei" + invitationToken);
        if(invitationToken){
            adminService.associateToken(invitationToken).then( (response) => {

                if(!response){
                    setErrorMessage("Código inválido!");
                    setIsLoading(false);
                }
                setIsLoading(false);
                history.push("Home");
            })
        }
    }, [setIsLoading, setErrorMessage, invitationToken, history]);

    return (

        <div className="Subscription">

            <SubscriptionForm
                setValue={setInvitationToken}
                errorMessage={errorMessage}
                placeholder="Código"
                title='Convite'
                subTitle='Código'
                theme='sub-btn-green'
                iconText='Associar'
                isInvitationForm={true}
                onClick={onSubmitInvitation}
            >
                {/* <div className='sub-form'></div> */}
            </SubscriptionForm>

            <div className='separatorSubForms'/>

            <SubscriptionForm
                setValue={setInvitationToken}
                errorMessage={errorMessage}
                placeholder="Código"
                title='Free Trial'
                subTitle='Código'
                theme='sub-btn-blue'
                iconText='Subscrever'
                isInvitationForm={false}
                icon={<Check/>}
                onClick={onSubmitInvitation}
            >
                <div className="check-list-content">
                    <div className="check-list">
                        <Check className='icon-chek'/>
                        <div className="btn-text"> Pesquisas limitadas</div>
                    </div>
                    <div className="check-list">
                        <Check className='icon-chek'/>
                        <div className="btn-text"> Criação de orçamentos</div>
                    </div>
                    <div className="check-list">
                        <Check className='icon-chek'/>
                        <div className="btn-text"> Retailers limitados</div>
                    </div>
                </div>
                {/* <div className='sub-form'></div> */}
            </SubscriptionForm>

            <div className='separatorSubForms'/>

            <SubscriptionForm
                setValue={setInvitationToken}
                errorMessage={errorMessage}
                placeholder="Código"
                title='Adesão'
                subTitle='Código'
                theme='sub-btn-gold'
                iconText='Subscrever'
                isInvitationForm={false}
                onClick={onSubmitInvitation}
            >
                <div className="check-list-content">
                    <div className="check-list">
                        <Check className='icon-chek'/>
                        <div className="btn-text"> Pesquisas Ilimitadas</div>
                    </div>
                    <div className="check-list">
                        <Check className='icon-chek'/>
                        <div className="btn-text"> Criação de orçamentos</div>
                    </div>
                    <div className="check-list">
                        <Check className='icon-chek'/>
                        <div className="btn-text"> Retailers Ilimitados</div>
                    </div>                   
                </div>
                {/* <div className='sub-form'></div> */}
            </SubscriptionForm>

        </div>
    )
}
