import React, { useEffect } from 'react';
import './App.css';
import {  Switch} from 'react-router-dom';
import HomeModule from './Modules/Home/Home.Module';
import HomeLoginPage from './Modules/Home/HomeLoginPage';

import { GuardProvider, GuardedRoute } from 'react-router-guards';
import {  useIsAuthenticated } from '@azure/msal-react';
import { IdentityService } from './Infrastructure/Identity/IdentityService';
import { usePrivateGuard, usePublicGuard } from './Infrastructure/Identity/Hooks/RoutingGuards';


export function AppMainModule(props: any) {

    const isAuthenticated = useIsAuthenticated();
    const privateGuard = usePrivateGuard();
    const publicGuard = usePublicGuard();


    useEffect( () => {
      IdentityService.setAuthenticated(isAuthenticated);
    }, [isAuthenticated]);

    return (

        <div className="main">

        <GuardProvider guards={[publicGuard]} >
          <Switch>
            <GuardedRoute exact path="/"> <HomeLoginPage/> </GuardedRoute>
          </Switch>
        </GuardProvider>



        <GuardProvider guards={[privateGuard]} >
          <Switch>
            <GuardedRoute path="/app/"> <HomeModule/> </GuardedRoute>
          </Switch>
        </GuardProvider>

        </div>
    );
  }
