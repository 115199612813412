import { ConfigurationProvider } from './Configuration/ConfigurationProvider';
import { IdentityProvider } from './Identity/IdentityProvider';

export class Startup {

    static async Run( app: () => void) {

        let a = performance.now();

        await ConfigurationProvider.init();

        await Promise.all([
            IdentityProvider.init()
        ]);


        let b = performance.now();
        console.log(`Call to doSomething took ${b - a} milliseconds`)
        app();
    }

}
