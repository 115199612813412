import { ReactComponent as MenuUp} from '../../../../Assets/svgs/menu-up.svg'
import { ReactComponent as MenuDown} from '../../../../Assets/svgs/menu-down.svg'
import './Accordion.css'
import { useCallback, useMemo, useState} from 'react';
import { FormField } from '../FormField/FormField';
import { InputAutoComplete } from '../../../../Components/InputAutoComplete/InputAutoComplete';
import { Input } from '../../../../Components/Atoms/Input/Input';
import { CbOption } from '../../../../Components/ComboBox/ComboBox';
import { Checkbox } from '../Checkbox/Checkbox';

interface IProps{
    Title: string;
    retailer: string;
    model: string;
    stock: boolean;
    icIv: string;
    maxPrice: string;
    minPrice: string;
    errorMessageMinPrice?: string;
    errorMessageMaxPrice?: string;
    priceInterval: number[];
    filters?: number;
    allRetailers: CbOption[];
    setRetailer: (retailer: string) => void;
    setMinPrice: (minPrice: string) => void;
    setMaxPrice: (maxPrice: string) => void;
    setStock: (retailer: boolean) => void;
    setModel: (retailer: string) => void;
    setIcIv: (retailer: string) => void;
    handleEnterPressed: (ev: React.KeyboardEvent<Element>) => false | undefined
}

export function AdvancedSearch(props: IProps){
    
    const[isOpen, setIsOpen] = useState(false);

    const onInputChange = useCallback((event: any) => {
        let regex = /\d+(\.\d*)?/g;
        let arr = regex.exec(event) || [""];
        let val = "" + event;
        if(arr[0] == ""){
            return "";
        } else {
            return arr[0];
        }
    },[]);

    const numberOfFilters = useMemo(() =>{
        let numberOfFilters = 0;
        if(props.minPrice != ""){
            numberOfFilters++;
        }
        if(props.maxPrice != ""){
            numberOfFilters++;
        }
        if(props.retailer != ""){
            numberOfFilters++;
        }
        if(props.model != ""){
            numberOfFilters++;
        }
        if(props.icIv != ""){
            numberOfFilters++;
        }
        return numberOfFilters;
    },[props.minPrice, props.maxPrice, props.retailer, props.model, props.icIv])

    return(
        <div className="container-advanced">
            <div className="container-advanced-close">
                <div className="container-advanced-title">{props.Title}</div>
                    {numberOfFilters > 0 ?
                        <div className='number-filters'>{numberOfFilters}</div>
                    :
                        null
                    }
                    {isOpen ?
                        <MenuUp className="container-advanced-svg" onClick={() => setIsOpen(false)}/>
                    :
                        <MenuDown className="container-advanced-svg" onClick={() => setIsOpen(true)}/>
                    }
                </div>
            
                { isOpen ?
                    <>  
                        <div className='container-advanced-inputs'>
                            <FormField className="filter-input">
                                <InputAutoComplete css={""} placeholder={"Fornecedor"} selectedOp={props.retailer} optionsList={props.allRetailers} onChange={props.setRetailer} />
                            </FormField>
                            <FormField className="filter-input">
                                <Input type="text" className="filter-input" placeholder="Modelo" value={props.model} onChange={(ev) => props.setModel(ev.target.value)} onKeyPress={props.handleEnterPressed} />
                            </FormField>
                            <FormField className="filter-input">
                                <Input type="text" className="filter-input" placeholder="IC/IV" value={props.icIv} onChange={(ev) => props.setIcIv(ev.target.value)} onKeyPress={props.handleEnterPressed} />
                            </FormField>
                            <FormField className="filter-input" errorMessages={props.errorMessageMinPrice}> 
                                <Input type="text" errorMessage={props.errorMessageMinPrice} className="filter-input"
                                 placeholder={props.priceInterval[0].toString()} value={props.minPrice} 
                                 onChange={(ev) => {props.setMinPrice(onInputChange(ev.target.value))}} onKeyPress={props.handleEnterPressed} />
                            </FormField>
                            <FormField className="filter-input" errorMessages={props.errorMessageMaxPrice}>
                                <Input type="text" errorMessage={props.errorMessageMaxPrice} className="filter-input"
                                 placeholder={props.priceInterval[1].toString()} value={props.maxPrice}
                                  onChange={(ev) => props.setMaxPrice(onInputChange(ev.target.value))} onKeyPress={props.handleEnterPressed} />
                            </FormField>
                            <FormField className="filter-input">
                                <Checkbox
                                    isChecked={props.stock}
                                    text="Stock"
                                    onToogle={props.setStock}
                                    className={"advancedSearch"}
                                />
                            </FormField>
                        </div>
                    </>
                : null
                }
        </div>
    );
}

