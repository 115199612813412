import { AuthError, EventMessage } from "@azure/msal-browser";

interface IdentityClaims {
    exp: number;
    iat: number;
    iss:  string;
    nbf: number;
    nonce:  string;
    sub:  string;
    tfp:  string;
    ver:  string;
    aud: string;
    auth_time: number;
}

export interface UserClaims extends IdentityClaims  {
    city: string;
    country:  string;
    emails: string[],
    name:  string;
    jobTitle:  string;
    postalCode:  string;
    state: string;
    streetAddress:  string;
};




export class IdentityHelper {



    static getUserDisplayNameFromClaims(principal: UserClaims){
        // let familyName = principal.extension_FamilyName
        // ?? (principal as any)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"]
        // ?? (principal as any)["surname"]
        // ?? "";

        // let firstName = principal.given_name
        // ?? "";

        return principal.name;
    }

    static getUserEmailFromClaims(principal: UserClaims){

        if(principal
            && principal.emails
            && Array.isArray(principal.emails)
            && principal.emails.length > 0){

            return principal.emails[0];
        }

        else
            return null;
    }



    static errorMessageContains(message: EventMessage, text: string){

        return (message
        && message.error
        && message.error instanceof AuthError
        && message.error.errorMessage
        && message.error.errorMessage.includes(text));

    }
}
