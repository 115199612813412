import { useContext } from 'react';
import { UIContext } from '../UIServices';


export function useGlobalPopup(){

    const context = useContext(UIContext);
    if(!context){
        throw new Error("useViewport must be used within UIServices");
    }

    return {setShowPopup: context.setShowPopup, setPopupContent: context.setPopupContent, isShowPopup: context.showPopup}
}

export function useRenderGlobalPopup(){

    const context = useContext(UIContext);
    if(!context){
        throw new Error("useViewport must be used within UIServices");
    }

    return {isShowPopup: context.showPopup, popupContent: context.popupContent}
}