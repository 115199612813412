import { useEffect, useState, useCallback } from 'react';
import { BudgetService } from '../../Services/BudgetService';
import { ContainerLayout } from '../../Layouts/Container/ContainerLayout';
import { Table } from '../../Components/Tables/Table/Table';
import { BudgetDTO, BudgetTyreDTO } from '../../Models/API/Clients/BudgetPostResponseDTO';
import { TyreDescriptionTableCell } from '../SearchPage/Components/TyreDescriptionTableCell';
import { useIdentityUserEmail } from '../../Infrastructure/Identity/Hooks/useIdentityUserEmail';
import { ReactComponent as DeleteBudgetSVG } from '../../Assets/svgs/trash.svg'
import "./BudgetsPage.css"
import { useGlobalPopup } from '../../Infrastructure/UI/Hooks/useGlobalPopup';
import { useGlobalLoading } from '../../Infrastructure/UI/Hooks/useGlobalLoading';
import { SuccessPopUp } from '../../Components/Molecules/PopUp/SuccessPopUp';
import { FullScreenLoader } from '../../Components/FullScreenLoader/FullScreenLoader';

interface IProps{
    setHeaderTitle: (title: string) => void;
}

export function BudgetsPage(props: IProps){
    const {userEmail} = useIdentityUserEmail();

    const [totalBudgets, setTotalBudgets] = useState(0);
    const [budgetsList, setBudgetsList] = useState<BudgetDTO[]>([]);
    const [noData, setNoData] = useState<boolean>(false)
    
    //--- Popup e Full Screen Loader --------------------------------------------
    const {setShowPopup, setPopupContent} = useGlobalPopup();
    const {setShowFSL, setFSLContent} = useGlobalLoading();
    
    
    const loadBudgets = useCallback(() => {
        setShowFSL(true)
        setFSLContent(<FullScreenLoader showLoader={true}/>)

        const budgetService = new BudgetService();
        budgetService.getAllClientBudgets({desc: "", budgetId:null, dateTime: null, keyWord: ""}).then(res => {
            setTotalBudgets(res.total)
            setBudgetsList(res.budgets);
            setShowFSL(false);
            if(res.budgets.length === 0) setNoData(true)
        });
        
    },[setTotalBudgets, setBudgetsList, setShowFSL, setFSLContent])


    const deleteBudget = useCallback(async (budgetId: number) => {
        setShowFSL(true)
        setFSLContent(<FullScreenLoader />)
        
        const budgetService = new BudgetService();
        try{
            await budgetService.deleteBudget(budgetId).then(res => {
                setShowFSL(false)
                setShowPopup(true)
                setPopupContent(<SuccessPopUp title={"Remover Orçamento"} message={"Orçamento removido com sucesso!"} onClose={() => setShowPopup(false)} />);
                loadBudgets();
            });
        }catch(_){
            setShowFSL(false)
            setShowPopup(true)
            setPopupContent(<SuccessPopUp title={"Remover Orçamento"} message={"Ocorreu um erro. Orçamento não removido!"} onClose={() => setShowPopup(false)} />);
        }
    },[setShowFSL, setFSLContent, setShowPopup, setPopupContent, loadBudgets])

    
    useEffect(() => {
        props.setHeaderTitle("Orçamentos");
        loadBudgets();
    }, []);


    return(
        <>
            {budgetsList?.map((bud, idx) =>
                <>
                    <div className="budget-header"> 
                        <h2 className="budgetsDescription">{bud.description}</h2>
                        <div className="actions">
                            <div className="circle-background" onClick={() => deleteBudget(bud.budgetId)}><DeleteBudgetSVG className="deleteBudget-svg"/></div>
                        </div>
                    </div>   
                    <ContainerLayout>
                        <Table
                            className="n4mp-table-nopagination"
                            dataTotalItems={totalBudgets}
                            dataList={bud.tyresList}
                            columnDefinition={
                            [
                                {
                                    key: 'reference',
                                    headerLabel: 'Referência',
                                    sortable: true,
                                    valueTransform: (value: BudgetTyreDTO) => <>{value.reference}</>
                                },
                                {
                                    key: 'description',
                                    headerLabel: 'Descrição',
                                    sortable: true,
                                    valueTransform: (value: BudgetTyreDTO) => <TyreDescriptionTableCell item={value}/>
                                },
                                {
                                    key: 'retailer',
                                    headerLabel: 'Distribuidor',
                                    sortable: true,
                                    valueTransform: (value: BudgetTyreDTO) => <>{value.retailerName || '-'}</>
                                },
                                {
                                    key: 'brand',
                                    headerLabel: 'Marca',
                                    sortable: true,
                                    valueTransform: (value: BudgetTyreDTO) => <>{value.brand || '-'}</>
                                },
                                {
                                    key: 'state',
                                    headerLabel: 'Estado',
                                    sortable: true,
                                    valueTransform: (value: BudgetTyreDTO) => <>{value.state || '-'}</>
                                },
                                {
                                    key: 'stock',
                                    headerLabel: 'Stock',
                                    sortable: true,
                                    valueTransform: (value: BudgetTyreDTO) => <>{value.stock || '-'}</>,
                                },
                                {
                                    key: 'netprice',
                                    headerLabel: 'Preço Net',
                                    sortable: true,
                                    valueTransform: (value: BudgetTyreDTO) => <>{value.priceNet.toFixed(2)}</>,
                                },
                                {
                                    key: 'pvpprice',
                                    headerLabel: 'Preço Venda',
                                    sortable: true,
                                    valueTransform: (value: BudgetTyreDTO) => <>{value.pvpPrice.toFixed(2)}</>,
                                },
                                {
                                    key: 'finalprice',
                                    headerLabel: 'Preço Final ' + '\n (c/Desc)',
                                    sortable: true,
                                    valueTransform: (value: BudgetTyreDTO) => <>{value.finalPrice.toFixed(2)}</>,
                                }
                            ]
                        }
                        />

                    </ContainerLayout>
                    <div className="table-budget"/>
                </>
            )}
            {noData 
            ?
                <p>
                <div className="budget-lenght">Sem Dados</div>

                <div className="budget-lenght">Crie o seu primeiro orçamento na página de pesquisa.</div>
                </p>
            :
                null
            }
        </>
    );
}
