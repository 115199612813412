import { useContext } from "react";
import { UIContext } from "../UIServices";

export function useGlobalLoading() {
    const context = useContext(UIContext);
    if(!context){
        throw new Error("useGlobalLoading must be used within UIServices");
    }

    return { setShowFSL: context.setShowFSL, setFSLContent: context.setFSLContent }
}

export function useRenderGlobalLoading(){
    const context = useContext(UIContext);
    if(!context){
        throw new Error("useViewport must be used within UIServices");
    }

    return {showFSL: context.showFSL, FSLContent: context.FSLContent}
}