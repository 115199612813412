import { useState } from 'react';
import { IconButton } from '../../Atoms/IconButton/IconButton';
import { useCallback } from 'react';
import { ReactComponent as SumSVG } from "../../../Assets/sum.svg";
import "./AllPopUps.css"


interface IProps{
    setShowPopUp: (state: boolean) => void;
    onSave: () => void;
    setBudgetName: (name: string) => void;
    verifyRepeatedName: (name: string) => boolean;
}


export function SavePopUp(props: IProps){

    const[inputText, setInputText] = useState("")
    const[errorMessage, setErrorMessage] = useState("");

    
    const validateForm = useCallback(() => {
        if(inputText === "") setErrorMessage("Por favor insira um nome / descrição!")
        if(props.verifyRepeatedName(inputText)) setErrorMessage("Descrição já existe! Introduza uma nova descrição!")
        if(inputText !== "" && props.verifyRepeatedName(inputText) === false){
            props.setShowPopUp(false)
            props.onSave();
            setErrorMessage("")
        }
    },[props, inputText, setErrorMessage])


    return(
        <div className="popup-container">
            <div className="popup-title">Adicionar Orçamento</div>
            <div className="popup-separator"/>
            
            {errorMessage ? <h5>{errorMessage}</h5> : null}
    
            <div className="popup-message">Nome / descrição:</div>
            <input type="text" className="input-info" onChange={(e) => {setInputText(e.target.value); props.setBudgetName(e.target.value)}} />
    
            <div className="popup-buttons">
                <div className="popup-div-button"><IconButton className="btn-gray" text={"Cancelar"} onClick={() => props.setShowPopUp(false)} ></IconButton></div>
                <div className="popup-button-separator"/>
                <div className="popup-div-button"><IconButton className="btn-green" text={"Adicionar"} icon={<SumSVG/>} onClick={() => validateForm()}></IconButton></div>
            </div>
        </div>
    );
}