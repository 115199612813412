import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Switch, Route } from "react-router";
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Header } from "../../Components/Molecules/Header/Header";
import { Sidebar, MenuItem } from "../../Components/Molecules/Sidebar/Sidebar";
import { MainPage } from "../MainPage/MainPage";
import { ProvidersPage } from "../Providers/ProvidersPage";
import SearchPage from "../SearchPage/SearchPage";
import { SettingsPage } from '../SettingsPage/SettingsPage';
import { ReactComponent as SettingsSvg } from './../../Assets/svgs/settings.svg';
import { ReactComponent as DashboardSvg } from './../../Assets/svgs/dashboard.svg';
import { ReactComponent as DeliveryTruckSvg } from './../../Assets/svgs/delivery-truck.svg';
import { ReactComponent as MagSvg } from './../../Assets/svgs/magnifying-glass.svg';
import { BudgetsPage } from '../Budgets/BudgetsPage';
import { PopupContainer } from '../../Infrastructure/UI/Components/PopupContainer/PopupContainer';
import { useRenderGlobalPopup } from '../../Infrastructure/UI/Hooks/useGlobalPopup';
import { useRenderGlobalLoading } from '../../Infrastructure/UI/Hooks/useGlobalLoading';
import { GuardedRoute } from "react-router-guards";
import { AdminService } from "../../Services/Http/AdminService";
import { SubscriptionPage } from "../SubscriptionPage/SubscriptionPage";

let menuItemsTop1: MenuItem[] = [
    {
        icon: "Home",
        url: "homeIcon",
        imgAltName: "home",
        toLink: "/app/Home",
        svg: <DashboardSvg />
    },
    {
        icon: "Pesquisa",
        url: "searchIcon",
        imgAltName: "tires",
        toLink: "/app/Home/Search",
        svg: <MagSvg />
    },
    {
        icon: "Distribuidores",
        url: "providersIcon",
        imgAltName: "provider",
        toLink: "/app/Home/Providers",
        svg: <DeliveryTruckSvg />
    } ,
    {
        icon: "Orçamentos",
        url: "providersIcon",
        imgAltName: "budget",
        toLink: "/app/Home/Budgets",
        svg: <DeliveryTruckSvg />
    }
]

let menuItemsTop2: MenuItem[] = [
    {
        icon: "Subscription",
        url: "homeIcon",
        imgAltName: "home",
        toLink: "/app/Subscription",
        svg: <DashboardSvg />
    }
]

let menuItemsBottom: MenuItem[] = [
    {
        icon: "Definições",
        url: "settingsIcon",
        imgAltName: "settings",
        toLink: "/app/Home/Settings",
        svg: <SettingsSvg />
    }
]

export default function HomeModule() {
    const [searchReference, setSearchReference] = useState("");
    const [headerTitle, setHeaderTitle] = useState("Home");
    const match = useRouteMatch();
    let history = useHistory();
    const {isShowPopup, popupContent} = useRenderGlobalPopup();
    const {showFSL, FSLContent} = useRenderGlobalLoading();
    const [hasClientId, setHasClientId] = useState<boolean>(false);
    

    const onClick = useCallback((path: string) => {
        history.push(path);
    },[history]);

    useEffect(() => {
        if(searchReference)
            onClick("Home/Search?reference="+searchReference);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchReference])

    const menuItemsTop = useMemo(() => {
        return hasClientId ? menuItemsTop1 : menuItemsTop2
    },[hasClientId])

    useEffect(() => {
        const adminService = new AdminService();
        adminService.verify().then( (r) => {
            setHasClientId(!!r.clientId);
            }
        );
    },[])

    return (
        <>
            <div className='standard-layout'>
                <div className='side-standard'>
                    <Sidebar itemsTop={menuItemsTop} itemsBottom={menuItemsBottom}/>
                </div>
                <div className='main-standard '>
                    <header>
                        <Header title={headerTitle}/>
                    </header>

                    <Switch>
                        <GuardedRoute path={`${match.path}Home/Budgets`} >
                            <BudgetsPage setHeaderTitle={setHeaderTitle}/>
                        </GuardedRoute>
                        <GuardedRoute path={`${match.path}Home/Providers`} >
                            <ProvidersPage setHeaderTitle={setHeaderTitle}/>
                        </GuardedRoute>
                        <GuardedRoute path={`${match.path}Home/Search`} >
                            <SearchPage setHeaderTitle={setHeaderTitle}/>
                        </GuardedRoute>
                        <GuardedRoute path={`${match.path}Home/Settings`} >
                            <SettingsPage setHeaderTitle={setHeaderTitle}/>
                        </GuardedRoute>

                        <GuardedRoute path={`${match.path}Home`} >
                            <MainPage setReference={setSearchReference} setHeaderTitle={setHeaderTitle}/>
                        </GuardedRoute>

                        <GuardedRoute path={`${match.path}Subscription`}>
                            <SubscriptionPage setHeaderTitle={setHeaderTitle}/>
                        </GuardedRoute>

                    </Switch>
                </div>
            </div>

            {isShowPopup ? <PopupContainer>{popupContent}</PopupContainer> : null}

            {showFSL ? <>{FSLContent}</> : null}   
        </>
    );
}

interface IPageLayoutProps {
    children: React.ReactChildren | React.ReactChild | React.ReactElement | React.ReactElement[] | React.ReactNode
}
