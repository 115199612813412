import { Height } from "@material-ui/icons";
import React from "react";
import "./ContentBox.css";

interface IProp {
    defaultPadding?: boolean
    fullHeight?: boolean
    theme?: 'Transparent'|'' |'White'| 'Gold';
    fitContent?: boolean
    overrideStyle?: boolean //TODO::
    contentClassName?:string
    className?:string
}

export default class ContentBox extends React.Component<IProp> {
    render() {
      return (
          <div className={`contentBox ${this.props.fullHeight ? `contentBox-fullHeight` : null} ${this.props.className} contentBox-${this.props.theme||''}`}
          style={this.props.overrideStyle? {} : {width:this.props.fitContent? `fit-content` : "95%" , height:this.props.fitContent? `fit-content` : ""} }
          >
              <div className={`
                      ${this.props.defaultPadding ? `contentBox-content` : null}
                      ${this.props.fullHeight ? `contentBox-fullHeight` : null}
                      ${this.props.contentClassName}
                      `}>
                {this.props.children}
              </div>
          </div>
      );
    }
}
