import React, { useMemo } from 'react';

import './FormField.css';

interface IFormFieldProps<T> {
    label?: string;
    children: React.ReactNode;
    className?: string;
    mode?: 'vertical' | 'horizontal'
    formControl?: IFormControl<T>;
    errorMessages?: string;
}

export interface IFormControl<T> {
    value: Optional<T>;
    setValue: React.Dispatch<React.SetStateAction<Optional<T>>>;
    errorMessages: string[];
    isValid: boolean;
    validate: () => boolean;
}

export type Optional<T> = T | undefined;
export type ErrorMessage = string;
export type ValidatorFunction<T> = (value: T) => ErrorMessage | null;


export function FormField<T>(props: IFormFieldProps<T>){

    const css = useMemo(() => {
        let csss = ["n4mp-form"];
        if(props.className){
            csss.push(props.className);
        } 

        if(props.errorMessages){
            csss.push("error");
        }

        return csss.join(" ");
    },[props.className, props.mode]);

    return (
        <div className={css}>
            <div className='label'>{props.label}</div>
            {props.children}

            { props.errorMessages && props.errorMessages.length ?
                <div className="error-message">{props.errorMessages}</div>
            :null}
        </div>
    )
}
