import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Startup } from './Infrastructure/Startup';
import { BrowserRouter as Router} from 'react-router-dom';
import { SPAApp } from './Infrastructure/SPAApp';

Startup.Run(() => {
  // axios.interceptors.response.use( (response) => response, (error) => {

  //   if (error.response && error.response.status && error.response.status === 401) {
  //     console.log("REDIRECT FROM AXIOS");
  //     window.location.href = IdentityService.loginUrl()  || "";
  //     window.location.hash = "";
  //   } else {
  //     return Promise.reject(error);
  //   }
  // });


  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <SPAApp>
          <App />
        </SPAApp>
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(console.log);
})