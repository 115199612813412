import { useCallback, useEffect, useState } from "react";
import { CbOption } from '../../../Components/ComboBox/ComboBox';
import { AccessDTO } from "../../../Models/Domain/AccessDTO";
import { Retailer } from "../../../Models/Domain/Retailer";
import "./RetailerForm.css";
import { IconButton } from '../../../Components/Atoms/IconButton/IconButton';

export interface IRetailerFormData{
    retailerId: number;
    userName: string;
    password: string;
}

interface IRetailerFormProps {
    retailersList: Retailer[];
    value?: AccessDTO;
    buttonText: string;
    theme: string;
   // icon: string;
    icon: React.ReactNode;
    userText: string;
    passText: string;
    onCompleted: (formData: IRetailerFormData) => void;
}

export function RetailerForm(props: IRetailerFormProps){
    const [retailerId, setRetailerId] = useState(0);
    const [userName, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [userText, setUserText] = useState("");
    const [passText, setPassText] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [buttonLabel, setButtonLabel] = useState("Adicionar");

    const [disableRetailerDropDown, setDisableRetailerDropDown] = useState(false);
   
    const [allRetailersComboBox, setAllRetailersComboBox] = useState<CbOption[]>([]);

    useEffect(() => {
        setAllRetailersComboBox(props.retailersList.map((retailer) : CbOption => ({
            key: retailer.id,
            label: retailer.name
        })));
        if((!props.value || !props.value.retailerId) && props.retailersList && props.retailersList.length){
            setRetailerId(props.retailersList[0].id);
        }
    }, [props.retailersList])

    
    useEffect(() => {
        if(props.value){
            console.log(props.value);
            //setRetailerName(props.value.);
            setRetailerId(props.value.retailerId);
            setUsername(props.value.userName);
            //setPassword(props.value.password);
            setButtonLabel(props.buttonText);
            setPassText(props.passText);
            setButtonLabel(props.userText);
            setDisableRetailerDropDown(true);
        }
    },[props.value]);

    const validateForm = useCallback(() => {
        if(!retailerId || !userName || !password){
            setErrorMessage("Por favor, preencha todos os campos!");
            return false;
        }
        setErrorMessage("");
        return true;
    }, [retailerId, userName, password, setErrorMessage]);

    const onSubmit = useCallback(()=> {
        if(validateForm()){
            props.onCompleted({retailerId, userName, password})
        }
    },[retailerId, userName, password, props.onCompleted]);

    return (
        <>
            {errorMessage 
                ? <h5 className="Activities-ScheduleArea-PopUp-Title">{errorMessage}</h5>
                : null
            }
        
            {/* <div className="separator-provider"/> */}
    
            <div className="retailerForm-data-container">
                <div className="data-title">Distribuidores</div>
                <div className="select-retailer">
                    <select disabled={disableRetailerDropDown}  value={retailerId} onChange={(event) => setRetailerId(event.target.value as unknown as number)}>
                        {allRetailersComboBox.map((opt, idx) => <option key={idx} value={opt.key}>{opt.label}</option>)}
                    </select> 
                </div>
            </div>

            <div className="retailerForm-data-container">
                <div className="data-title">{props.userText}</div>
                <input type="text" className="input-info"  onChange={(e) => setUsername(e.target.value)} placeholder={props.value?.userName}></input>   
            </div>

            <div className="retailerForm-data-container">
                <div className="data-title">{props.passText}</div>
                <input type="text" className="input-info"  onChange={(e) => setPassword(e.target.value)} placeholder={""}></input>   
            </div>

            <div className="retailerForm-button">
                <IconButton className={props.theme} text={props.buttonText} icon={props.icon} onClick={onSubmit} ></IconButton>
            </div>
        </>
    );
}