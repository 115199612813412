import './FullScreenLoader.css'
import { Loader } from '../../../../Components/Loader/Loader';


export function FullScreenLoader() {

    return (
        <div className="full-screen-loader">
            <div className="loader">
                <Loader />
            </div>
        </div>
    )
}