
import React from 'react';
import { Redirect,  Switch } from 'react-router-dom';
import { SplashScreen } from './Modules/Common/SplashScreen/SplashScreen';
import { IdentityRoute } from './Infrastructure/Identity/Components/IdentityRoute';


export function AppIdentityModule() {

    return (
        <Switch>
            <IdentityRoute path='/Identity/SignIn'              exec={(svc)=> svc.login()}  component={<SplashScreen text="Logging in..."/>} />
            <IdentityRoute path='/Identity/SignUp'              exec={(svc)=> svc.signUp()}  component={<SplashScreen text="Loading..."/>} ifAuthenticated={<Redirect to='/home'/>} />
            <IdentityRoute path='/Identity/ForgottenPassword'   exec={(svc)=> svc.forgottenPassword()}  component={<SplashScreen text="Loading..."/>} />
            <IdentityRoute path='/Identity/SignOut'             exec={(svc)=> svc.logout()}  component={<SplashScreen text="Loading..."/>}  />

        </Switch>
    );
}
