import { AppConfiguration } from './AppConfiguration';



declare global {
    interface Window {
        AppConfiguration: AppConfiguration
    }
}


export class ConfigurationProvider {



    static async init() {
        console.log("ENV", process.env.NODE_ENV);
        if(process.env.NODE_ENV !== 'production'){
            console.log("Init Configuration");
            window.AppConfiguration = await ConfigurationProvider.readConfiguration();
        }
        //else: dotnet le os files e preenche o window.
    }

    static getConfiguration() : AppConfiguration {
        return window.AppConfiguration;
    }



    static async readConfiguration(): Promise<AppConfiguration> {

        const appconfiguration = await import('../../appconfiguration.json').then(module => module.default);
        const env = appconfiguration.Environment;
        const appConfigurationEnv = await import(`../../appconfiguration.${env}.json`).then(module => module.default);


        return ({
            ...appconfiguration,
            ...appConfigurationEnv
        });

    }

}
