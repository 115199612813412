export enum RetailerStatus{
    Ok = 1,
    Degraded = 2,
    Inactive = 3
}

export interface Retailer {
    id: number;
    name: string;
    status: RetailerStatus;
}
