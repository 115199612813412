import { ITableColumn, OptionOrder } from '../Table';
import './TableHeaderCell.css';
import { ReactComponent as ArrowDown } from '../../../../Assets/svgs/arrow-down.svg'
import { useCallback, useState } from 'react';

interface ITableHeaderCellProps<T> {
    column: ITableColumn<T>;
    columnId: number;
    onOrderClick?: (orderDirection: 'ASC' | 'DESC', columnId: number ) => void

    orderInfo: {
        isActive: boolean,
        direction: 'ASC' | 'DESC'
    }
}


export function TableHeaderCell<T>(props: ITableHeaderCellProps<T>){

    const [orderDirection, setOrderDirection] = useState<'ASC' | 'DESC'>('DESC');

    const handleOrderClick = useCallback(() => {
        let newOrder : 'ASC' | 'DESC' = orderDirection === 'ASC' ? 'DESC' : 'ASC';
        setOrderDirection(newOrder);
        if(props.onOrderClick){
            props.onOrderClick(newOrder, props.columnId)
        }
    }, [orderDirection, props]);

    return (
            <th className="n4mp-table-header-cell">
                <div className="header-content" onClick={handleOrderClick}>
                   {props.column.headerLabel}

                    {
                        props.orderInfo.isActive && props.orderInfo.direction == "ASC"? <ArrowDown className="svg" /> 
                        :
                        null
                    }

                    {
                        props.orderInfo.isActive && props.orderInfo.direction == "DESC" ? <ArrowDown className="svg-rotate" /> 
                        :
                        null
                    }
                   
                </div>
            </th>
    );
}
