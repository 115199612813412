import React, {useState, useEffect, useMemo} from 'react';
import './Input.css';


interface IInputProps{
    value?: any;
    placeholder?: string;
    name?: string;
    id?: any;
    className?: string;
    disabled?: boolean;
    type?: string;
    errorMessage?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
}

export function Input(props: IInputProps){

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        if(props.value !== value){
            setValue(props.value);
        }
    }, [props.value])

    const cssClasses = useMemo(() => {
        let csss = ["n4mp-input"];
        if(props.className){
            csss.push(props.className);
        } 

        if(props.errorMessage){
            csss.push("error");
        }

        return csss.join(" ");

    }, [props.className, props.errorMessage])

    return (
        <input
        className={cssClasses}
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        disabled={props.disabled} />
    )
}
