import "./ProvidersPage.css"
import { useCallback, useEffect, useState } from "react";
import ContentBox from '../../Components/ContentBox/ContentBox';
import { IRetailerFormData } from './RetailerForm/RetailerForm';
import { RetailerAccessesService } from '../../Services/RetailerAccessesService';
import { AccessDTO } from '../../Models/Domain/AccessDTO';
import { FullScreenLoader } from "../../Components/FullScreenLoader/FullScreenLoader";
import { Retailer, RetailerStatus } from "../../Models/Domain/Retailer";
import { RetailersAccessesSearchRequestDTO } from '../../Models/API/Clients/RetailersAccessesSearchRequestDTO';
import { Table } from '../../Components/Tables/Table/Table';
import { ContainerLayout } from '../../Layouts/Container/ContainerLayout';
import { IconButton } from '../../Components/Atoms/IconButton/IconButton';
import { AddRetailerAccess } from '../../Components/Molecules/PopUp/AddRetailerAccess';
import { EditRetailerAccess } from '../../Components/Molecules/PopUp/EditRetailerAccess';
import { DeleteConfirm } from '../../Components/Molecules/PopUp/DeleteConfirm';
import { AdminService } from "../../Services/Http/AdminService";
import { EmailRequestDTO } from "../../Models/API/EmailRequestDTO";
import { useIdentityUserDisplayName } from "../../Infrastructure/Identity/Hooks/useIdentityUserDisplayName";
import { ReactComponent as SumSVG } from "../../Assets/sum.svg";
import { ReactComponent as PencilSVG } from '../../Assets/svgs/pencil.svg';
import { ReactComponent as TrashSVG } from '../../Assets/svgs/trash.svg';
import { ReactComponent as CheckSVG } from "../../Assets/svgs/check-circle.svg";
import { ReactComponent as StopSVG } from "../../Assets/svgs/close-circle.svg";
import { ReactComponent as AlertSVG } from "../../Assets/svgs/alert-circle.svg";


interface IProps{
    setHeaderTitle: (title: string) => void;
}


export function ProvidersPage(props: IProps) {

    const { userDisplayName } = useIdentityUserDisplayName();
    const adminService = new AdminService();

    const [totalRetailerAccesses, setTotalRetailerAccesses] = useState(0);
    const [allRetailers, setAllRetailers] = useState<Retailer[]>([]);
    const [allFunctionalRetailers, setAllFunctionalRetailers] = useState<Retailer[]>([]);
    const [allRetailersAccesses, setAllRetailersAccesses] = useState<AccessDTO[]>([]);

    const [retailersList, setRetailersList] = useState<AccessDTO[]>([]);
    const [selectedAccess, setSelectedAccess] = useState<AccessDTO>();

    //---- Tables --------------------------------------------------------------------
    const [isLoading, setIsLoading] = useState(false);
    const [pageLength, setPageLength] = useState(5);
    const [actualPage, setActualPage] = useState(0);
    const [orderBy, setOrderBy] = useState("");
    const [orderDirection, setOrderDirection] = useState("");

    //---- PopUps --------------------------------------------------------------------
    const [showAddPopUp, setShowAddPopUp] = useState(false);
    const [showEditPopUp, setShowEditPopUp] = useState(false);
    const [showDeletePopUp, setShowDeletePopUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        props.setHeaderTitle("Distribuidores");
    }, []);


    const getAllRetailersAccesses = useCallback(() => {
        let retailerAccessesService = new RetailerAccessesService();
        retailerAccessesService.getAllRetailersAccesses().then(retailersAccesses => {
            setAllRetailersAccesses(retailersAccesses);
        });
    },[setAllRetailersAccesses]);
    
    const getAllFunctionalRetailersAccesses = useCallback(() => {
        let retailerAccessesService = new RetailerAccessesService();
        retailerAccessesService.getAllFunctionalRetailers().then(retailers => {
            setAllFunctionalRetailers(retailers);
        });
    },[setAllFunctionalRetailers]);


    const getAllRetailers = useCallback(() => {
        let retailerAccessesService = new RetailerAccessesService();
        retailerAccessesService.getAllRetailers().then(retailers => {
            setAllRetailers(retailers);
        });
    },[setAllRetailers]);


    useEffect( ()=> {
        getAllRetailers()
        getAllFunctionalRetailersAccesses()
        getAllRetailersAccesses();
    }, []);


    const onAdd = useCallback((formData: IRetailerFormData) => {
        setIsLoading(true);
        const retailerAccessesService = new RetailerAccessesService();
        
        //TODO validar resposta do BE
        var retailerData: IRetailerFormData = {
            userName: formData.userName,
            password: formData.password,
            retailerId: formData.retailerId
        }

        if(allFunctionalRetailers.some(r => r.id === retailerData.retailerId) 
        && allRetailersAccesses.some(v => v.retailerId === retailerData.retailerId))
        {
            setErrorMessage("Já possui dados de acesso referentes ao distribuidor selecionado!");
            setSelectedAccess(allRetailersAccesses.filter(r => r.retailerId === retailerData.retailerId)[0]);
            setShowAddPopUp(false);
            loadRetailers();
            getAllRetailersAccesses();
            setShowEditPopUp(true);
            setIsLoading(false);
        }
        else{
            retailerAccessesService.createRetailerAccess(retailerData).then(() => {
            setShowAddPopUp(false);
            setIsLoading(false);
            loadRetailers();
            getAllRetailersAccesses();
            }).catch(err =>  {console.log(err)
                setShowAddPopUp(false);
                setIsLoading(false);
            });
        }
    }, [allRetailersAccesses, allFunctionalRetailers, retailersList, setIsLoading, setShowEditPopUp, setSelectedAccess, setShowAddPopUp, setErrorMessage]);


    const onEdit = useCallback((formData: IRetailerFormData) => {
        setIsLoading(true);
        //TODO validar resposta do BE
        var retailerAccessesService = new RetailerAccessesService();
        retailerAccessesService.putRetailersAccesse(formData)
        .then(accessDTOUpdated => {
            getAllRetailersAccesses();
          loadRetailers();
          setShowEditPopUp(false);
          setIsLoading(false);
        });
    }, [setIsLoading, setShowEditPopUp]);


    const onDelete = useCallback(() => {
        setIsLoading(true);
        //TODO validar resposta do BE
        var retailerAccessesService = new RetailerAccessesService();
        if(selectedAccess){
            retailerAccessesService.deleteRetailerAccess(selectedAccess.retailerId).then( (response) => {
                getAllRetailersAccesses();
                loadRetailers();
                setShowDeletePopUp(false);
                setIsLoading(false);
            })
        }
    }, [setIsLoading, setShowDeletePopUp, selectedAccess]);


    const onAddRetailerButtonClick = useCallback(() => {
        setShowAddPopUp(true);
    },[setShowAddPopUp]);


    const loadRetailers = useCallback(() => {
        var request: RetailersAccessesSearchRequestDTO = {
            pageLength: pageLength,
            currentPage: actualPage,
            orderBy: orderBy,
            orderDirection: orderDirection
        }
        //passar request no serviço
        const retailerAccessesService = new RetailerAccessesService();
        retailerAccessesService.getRetailersAccesses(request).then(tableDTO =>{
            setTotalRetailerAccesses(tableDTO.total);
            let retailerAccessesList = tableDTO.rows.map(access => {
                var retailerAccessObj: AccessDTO = {
                    retailerName: access.retailerName,
                    userName: access.userName,
                    password: access.password,
                    state: access.state,
                    retailerId: access.retailerId
                }
                return retailerAccessObj;
            })
            setRetailersList(retailerAccessesList);
        })
    },[setTotalRetailerAccesses, setRetailersList, pageLength, actualPage, orderBy, orderDirection]);


    const findStatus = useCallback((retailerId: number) => {
        let retailer = allRetailers.find(fr => fr.id === retailerId)
        if(retailer) 
            return retailer.status
    },[allRetailers])


    useEffect(( ) => {
        loadRetailers();
    },[actualPage, pageLength, orderDirection, orderBy])


    return(
        <div className="main">
            <div className="providersPage">

                <ContentBox overrideStyle={true} theme={"White"}>
                    <div className="add-content">
                        <div className="retailers">Acesso aos seus distribuidores </div>
                        <div className='add-button'>
                            <IconButton className="btn-green" text={"Adicionar"} icon={<SumSVG/>} onClick={onAddRetailerButtonClick}></IconButton>
                        </div>
                    </div>
                </ContentBox>

                <ContainerLayout className="container-provider">
                    <Table
                        isLoading={isLoading}
                        currentPage={actualPage}
                        itemsPage={pageLength}
                        onCurrentPageChanged={setActualPage}
                        onRowsPerPageChange={setPageLength}
                        dataTotalItems={totalRetailerAccesses}
                        dataList={retailersList}
                        columnDefinition={
                            [
                                {
                                    key: 'retailer',
                                    headerLabel: 'Distribuidor',
                                    sortable: true,
                                    valueTransform: (value: AccessDTO) => <>{value.retailerName}</>
                                },
                                {
                                    key: 'username',
                                    headerLabel: 'Utilizador',
                                    sortable: true,
                                    valueTransform: (value: AccessDTO) => <div className="cell-grow">{value.userName}</div>
                                },
                                {
                                    key: 'running',
                                    headerLabel: <div className="running-status">Estado do Serviço</div>,
                                    sortable: true,
                                    valueTransform: (value: AccessDTO) => 
                                        <div className="running-status">
                                            {findStatus(value.retailerId) === RetailerStatus.Ok       ? <CheckSVG className="statusSVG green"/> : null }
                                            {findStatus(value.retailerId) === RetailerStatus.Degraded ? <AlertSVG className="statusSVG yellow"/> : null }
                                            {findStatus(value.retailerId) === RetailerStatus.Inactive ? <StopSVG className="statusSVG red"/> : null }
                                        </div>
                                },
                                {
                                    key: 'state',
                                    headerLabel: 'Estado',
                                    sortable: true,
                                    valueTransform: (value: AccessDTO) => <>{value.state}</>
                                },
                                {
                                    key: 'removeAction',
                                    headerLabel: '',
                                    sortable: false,
                                    valueTransform: (value: AccessDTO) => <div className="n4mp-table-cell-align_center" onClick={() => {setSelectedAccess(value); setShowDeletePopUp(true);}}>
                                        <div className="n4mp-circle red">
                                            <div className="smallSVG">
                                                <TrashSVG/>
                                            </div>
                                        </div>
                                    </div>,
                                },
                                {
                                    key: 'editAction',
                                    headerLabel: '',
                                    sortable: false,
                                    valueTransform: (value: AccessDTO) => <div className="n4mp-table-cell-align_center" onClick={() => {setSelectedAccess(value); setShowEditPopUp(true);}}>
                                        <div className="n4mp-circle black">
                                            <div className="smallSVG">
                                                <PencilSVG/>
                                            </div>
                                        </div>
                                    </div>,
                                }
                            ]
                        }
                        paginationOptions={[
                            {key:5, label:5},
                            {key:10, label:10},
                            {key:20, label:20},
                        ]}
                    />
                </ContainerLayout>

                {/**************************** PopUps ****************************/}
                    <AddRetailerAccess
                        allRetailers={allFunctionalRetailers}
                        onAdd={onAdd}
                        setShowAddPopUp={() => setShowAddPopUp(false)}
                        showAddPopUp={showAddPopUp}
                    />

                    <EditRetailerAccess
                        allRetailers={allRetailers}
                        onEdit={onEdit}
                        selectedAccess={selectedAccess}
                        setShowEditPopUp={() => setShowEditPopUp(false)}
                        showEditPopUp={showEditPopUp}
                    />

                    <DeleteConfirm 
                        showDeletePopUp={showDeletePopUp}
                        setShowDeletePopUp={() => setShowDeletePopUp(false)}
                        onDelete={onDelete}
                    />
                {/*****************************************************************/}

                <FullScreenLoader showLoader={isLoading} />
            </div>
        </div>
    )
}
