import { useCallback, useEffect, useState } from 'react';
import './InputAutoComplete.css'


export interface CbOption{
    key: number;
    label: string;
}

interface IProps{
    optionsList: CbOption[];
    onChange: (selected: any) => void;
    selectedOp?: string;
    css?: string;
    placeholder: string;
}


export function InputAutoComplete(props: IProps){
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [options, setOptions] = useState<CbOption[]>([])
    const [selectedOp, setSelectedOp] = useState<string>()
    const [optionsFiltered, setOptionsFiltered] = useState<CbOption[]>([])

    
    const handleSelected = useCallback((op: CbOption) => {
        
        setSelectedOp(op.label)
        props.onChange(op.label)
    },[setSelectedOp, props])


    const filter = useCallback((word: string) => {
        setSelectedOp(word);
        let filteredValues = options.filter(op => op.label.toLowerCase().startsWith(word.toLowerCase()))
        setOptionsFiltered(filteredValues)
        props.onChange(word)
        setIsOpen(true)
    },[options, props, setOptionsFiltered, setIsOpen, setSelectedOp])

    // useEffect(() => {
    //     if(props.selectedOp !== selectedOp){
    //         setSelectedOp(props.selectedOp);
    //     }
    // }, [props.selectedOp])

    useEffect(() => {
        setOptions(props.optionsList)
        setOptionsFiltered(props.optionsList)
    },[props.optionsList])


    return(<>
         <div className="inputAutoComplete-container" onMouseLeave={() => setIsOpen(false)}>
            <div className="inputAutoComplete">
                <div className={"inputAutoComplete-group" + props.css}>
                    <input className="inputAutoComplete-text" value={props.selectedOp} placeholder={props.placeholder} onChange={(e) => filter(e.target.value)} />
                </div>
            </div>
            {isOpen
            ?
                <div className="inputAutoComplete-dropdown">
                    {optionsFiltered.map((op, idx) => {
                        return <div key={idx} className="inputAutoComplete-option" onClick={() =>{handleSelected(op); setIsOpen(false)}}>{op.label}</div>
                    })}
                </div>
            :
                null
            }
         </div>
        </>
    );
}