import { ReactComponent as SumSVG } from "../../../Assets/sum.svg";
import { ConditionalRender } from '../../Directives/ConditionalRender';
import PopUp from "../../PopUp/PopUp";
import { RetailerForm, IRetailerFormData } from '../../../Modules/Providers/RetailerForm/RetailerForm';
import { Retailer } from '../../../Models/Domain/Retailer';
import "./AllPopUps.css"

interface IProps {
    showAddPopUp: boolean;
    allRetailers: Retailer[];
    setShowAddPopUp: (state: boolean) => void;
    onAdd: (formData: IRetailerFormData) => void;
}

export function AddRetailerAccess(props: IProps){

    return (
        <ConditionalRender if={props.showAddPopUp}>
            <PopUp onClose={() => { props.setShowAddPopUp(false)}}>
                <div className="popup-container">
                    <div className="popup-title">Adicionar Acesso</div>
                    <div className="popup-separator"/>
                
                    <RetailerForm 
                        retailersList={props.allRetailers}
                        onCompleted={props.onAdd}
                        userText={"Utilizador"}
                        passText={"Palavra-Passe"}
                        theme={"btn-green"}
                        icon={<SumSVG/>}
                        buttonText={"Adicionar"}
                    />
                </div>
            </PopUp>
        </ConditionalRender>
    );
}