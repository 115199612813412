import { EmailRequestDTO } from "../../Models/API/EmailRequestDTO";
import { VerifyRequestDTO } from "../../Models/VerifyRequestDTO";
import { VerifyResponseDTO } from "../../Models/VerifyResponseDTO";
import { AppConfiguration } from "../Configuration/AppConfiguration";
import { HttpClient } from "./HttpClient";

export class AdminService {

    notifyDevTeam(request: EmailRequestDTO){
        return HttpClient.sessionRequest({
            method: "POST",
            url: `${AppConfiguration.backendUrl()}api/v1/Admin/NotifyByEmail`,
            data: request
        })
       .then((res) => {
            return res.data;
        })
    }

    verify(): Promise<VerifyResponseDTO>{
        return HttpClient.sessionRequest<VerifyResponseDTO>({
            method: "GET",
            url: `${AppConfiguration.backendUrl()}api/v1/Admin/Verify`,
        })
       .then((res) => {
            return res.data;
        })
    }

    getInvitationCode(): Promise<string>{
        return HttpClient.sessionRequest<string>({
            method: "get",
            url: `${AppConfiguration.backendUrl()}api/v1/Admin/InvitationToken`,
        }).then((res) => {
            return res.data;
        });
    };

    associateToken(invitationToken: string): Promise<boolean>{
        return HttpClient.sessionRequest<boolean>({
            method: "DELETE",
            url: `${AppConfiguration.backendUrl()}api/v1/Admin/ValidateInvitationToken/${invitationToken}`,
        }).then((res) => {
            return res.data;
        });
    };
}