import { useCallback, useState } from "react";
import { ReactComponent as MagSVG } from '../../../Assets/svgs/magnifying-glass.svg';
import { IconButtonType, IconButton } from '../../Atoms/IconButton/IconButton';
import "./SearchEngine.css"

interface IProps{
    setReference: (ref: string) => void;
}

export function SearchByVehicle(props: IProps) {
    const [searchText, setSearchText] = useState("");

    const handleText = useCallback((text: string) => {
        setSearchText(text);
    },[setSearchText])

    return(
        <>
            <div className="input-search">
                <div className="input-title">Matricula</div>
                <input type="text" className="input-ref" placeholder="Ex: 21-HF-83" onChange={(e) => handleText(e.target.value)}/>
            </div>
            <IconButton className="button-home-search" text="Pesquisar" icon={<MagSVG/>}  type={IconButtonType.PRIMARY}  onClick={() => {props.setReference(searchText)}} />
        </>
    )
}

/****************** OK *******************/