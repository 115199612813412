import { IconButton } from '../../Atoms/IconButton/IconButton';
import { ReactComponent as TrashSVG } from "../../../Assets/svgs/trash.svg";
import { ConditionalRender } from '../../Directives/ConditionalRender';
import PopUp from "../../PopUp/PopUp";
import "./AllPopUps.css"

interface IDeleteConfirmProps {
    setShowDeletePopUp: (state: boolean) => void;
    showDeletePopUp: boolean;
    onDelete: () => void;
}

export function DeleteConfirm(props: IDeleteConfirmProps){

    return (
        <ConditionalRender if={props.showDeletePopUp}>
            <PopUp onClose={() => { props.setShowDeletePopUp(false)}} > 
                <div className="popup-container">
                    <div className="popup-title">Eliminar Acesso</div>
                    <div className="popup-separator"/>

                    <div className="popup-message">Tem a cereteza que pretende eliminar o acesso?</div>
                    
                    <div className="popup-buttons">
                        <div className="popup-div-button"><IconButton className="btn-gray" text={"Cancelar"} onClick={() => props.setShowDeletePopUp(false)} ></IconButton></div>
                        <div className="popup-button-separator"/>
                        <div className="popup-div-button"><IconButton className="btn-red" text={"Eliminar"} icon={<TrashSVG/>} onClick={props.onDelete}></IconButton></div>
                    </div>
                </div>
            </PopUp>
        </ConditionalRender>
    );
}