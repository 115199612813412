import { useEffect, useState, useCallback } from "react";
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { IdentityHelper, UserClaims } from '../Identity.Helper';
import { IdentityProvider } from '../IdentityProvider';



export function useIdentityUserDisplayName() {
    const [userDisplayName, setUserDisplayName] = useState<string | null>(null);
    const [userDisplayNameIsLoading, setUserDisplayNameIsLoading] = useState(false);
    const [userDisplayNameError, setUserDisplayNameError] = useState(null);

    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();


    useEffect(() => {
        setUserDisplayNameIsLoading(true);


        if(isAuthenticated){
            const activeAccount = instance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
            const accounts = instance.getAllAccounts();

            const request = {
             ...IdentityProvider.getBaseLoginRequest(),
              account: activeAccount || accounts[0]
            };

            instance.acquireTokenSilent(request)
            .then(authResult => {
                setUserDisplayName(IdentityHelper.getUserDisplayNameFromClaims(authResult.idTokenClaims as UserClaims))
                setUserDisplayNameIsLoading(false);
                setUserDisplayNameError(null);
            })
            .catch((e) => {
                setUserDisplayName(null);
                setUserDisplayNameIsLoading(false);
                setUserDisplayNameError(e);
            })
        } else {
            setUserDisplayName(null);
            setUserDisplayNameIsLoading(false);
            setUserDisplayNameError(null);
        }
    }, [isAuthenticated]);


    return {userDisplayName, userDisplayNameIsLoading, userDisplayNameError};
}
