import React, { useMemo} from 'react';
import { ConditionalRender } from '../../Directives/ConditionalRender';
import CircularProgress from '@material-ui/core/CircularProgress';
import './IconButton.css';

export enum IconButtonType {
    PRIMARY,
    ACCENT,
    SECONDARY,
    WARN
}

const cssClassForType = (type: IconButtonType) => {
    switch(type){
        case IconButtonType.PRIMARY: return "iconbutton-primary";
        case IconButtonType.SECONDARY: return "iconbutton-secondary";
        case IconButtonType.ACCENT: return "iconbutton-accent";

        default: return "iconbutton-primary";
    }
};

export interface IIconButtonProps{
    text?: any;
    icon?: React.ReactNode;
    id?: any;
    className?: string;
    type?: IconButtonType;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    isLoading?: boolean;
}

export function IconButton(props: IIconButtonProps){

    const cssClasses = useMemo(() => {
        let css = "n4mp-iconbutton";
        let type = props.type ?? IconButtonType.PRIMARY;

        css = css + " " + cssClassForType(type);

        if(props.className){
            css = css + " " + props.className;
        }

        return css;
    }, [props.className, props.type])

    return(
        <div id={props.id} className={cssClasses} onClick={props.onClick}>
            <ConditionalRender if={props.isLoading}>
                <div className="n4mp-iconbutton-overlay">
                    <CircularProgress disableShrink size="15px"/>
                </div>
            </ConditionalRender>
            <div className="n4mp-iconbutton-content">
                {props.icon
                ?
                    <div className="btn-icon">{props.icon}</div>
                :
                    null
                }
                <div className="btn-text">{props.text}</div>
            </div>
        </div>
    )
}

/****************** OK *******************/