import React, { useState } from 'react';
import { ITableColumn } from '../Table';
import './TableRow.css';

interface ITableRowCellProps<T> {
    item: T,
    columnDefinition: ITableColumn<T>;
}

export function TableRowCell<T>(props: ITableRowCellProps<T>){
    const [css, setCss] = useState("n4mp-table-cell");


    // useEffect(() => {
    //     let classes = ["n4mp-table-cell", ...(props.columnDefinition?.cellClassNames || [])];
    //     setCss(classes.join(' '));
    // }, [props.columnDefinition])

    return (<td className={css}>{(props.columnDefinition.valueTransform && props.columnDefinition.valueTransform(props.item)) || props.item}</td>);
}


interface ITableRowProps<T> {
    item: T,
    columnDefinitions: ITableColumn<T>[]
}

export function TableRow<T>(props: ITableRowProps<T>){

    return (
        <tr>
            {props.columnDefinitions.map((col, cidx) => <TableRowCell key={cidx} item={props.item} columnDefinition={col}/>)}
        </tr>
    );
}
