import { AppConfiguration } from './../../Services/Configuration/AppConfiguration';
import { IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { IdentityService } from './IdentityService';
import { ConfigurationProvider } from '../Configuration/ConfigurationProvider';
import { b2cPolicies } from '../../Config/Identity.Config';
import { B2cPolicies } from './Models/B2cPolicies';

export class IdentityProvider {


    static msalInstance: IPublicClientApplication;
    static b2cPolicies: B2cPolicies;

    static async init() {
        this.b2cPolicies = this.generateB2CPolicies();
        let msalInstance = new PublicClientApplication(this.getConfiguration());
        IdentityService.initInstance(msalInstance);
        this.msalInstance = msalInstance;

    }

    static getMsalClient(): IPublicClientApplication {
        return IdentityProvider.msalInstance;
    }


    static getB2CPolicies(): B2cPolicies {
        return this.b2cPolicies;
      }


      static getBaseLoginRequest(){
          const identityConfig = ConfigurationProvider.getConfiguration().Identity;

          return ({
              scopes:identityConfig.Scopes
          });
      }



    private static getConfiguration() {
        const identityConfig = ConfigurationProvider.getConfiguration().Identity;
        return  ({
            auth: {
                clientId: identityConfig.ClientId,
                authority: this.getB2CPolicies().authorities.signUpSignIn.authority,
                knownAuthorities: [this.getB2CPolicies().authorityDomain.toLocaleLowerCase()],
                redirectUri: AppConfiguration.baseUrl(),
               // navigateToLoginRequestUrl: true,
            },
            cache: {
                cacheLocation: "localStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            },
            system: {

                loggerOptions: {
                    loggerCallback: (level:any, message:any, containsPii:any) => {


                        // if (containsPii) {
                        //     return;
                        // }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                        }
                    }
                }
            }
        });
    }


    private static generateB2CPolicies(): B2cPolicies {
        const identityConfig = ConfigurationProvider.getConfiguration().Identity;


        return({
            names: {
                signUpSignIn: identityConfig.SignUpSignInPolicyId,
                forgotPassword: identityConfig.ResetPasswordPolicyId,
                editProfile: identityConfig.EditProfilePolicyId,
                signUp: identityConfig.SignUpPolicyId,
            },
            authorities: {
                signUpSignIn: {
                    authority: `${identityConfig.Instance}${identityConfig.Domain}/${identityConfig.SignUpSignInPolicyId}`,
                },
                forgotPassword: {
                    authority: `${identityConfig.Instance}${identityConfig.Domain}/${identityConfig.ResetPasswordPolicyId}`,
                },
                editProfile: {
                    authority: `${identityConfig.Instance}${identityConfig.Domain}/${identityConfig.EditProfilePolicyId}`,
                },
                signUp: {
                    authority: `${identityConfig.Instance}${identityConfig.Domain}/${identityConfig.SignUpPolicyId}`,
                },
            },
            authorityDomain: identityConfig.Authority
        });
    }

}
