import "./InfoCard.css";
import { ContainerLayout } from '../../../Layouts/Container/ContainerLayout';

interface IInfoCardProps {
   title: string;
   value: any;
   icon: string;
   //icon: React.ReactNode;
}

export function InfoCard(props: IInfoCardProps) {
    return (   
        <ContainerLayout className="infoCard-container">
            <div className="infoCard-content">
                <div className="icon">
                    <img src={props.icon} alt={props.title}/>
                </div>
                <div className="info">
                    <div className="info_number">{props.value}</div>
                    <div className="info_text"> {props.title}</div>
                </div>
            </div> 
        </ContainerLayout>    
    );
}

/****************** OK *******************/