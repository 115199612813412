import { useCallback, useEffect } from 'react';
import { useHistory } from "react-router";
import { useState } from "react";
import { ClientService } from '../../Services/ClientService';
import tireIcon from "../MainPage/tire.png";
import retailerIcon from "../MainPage/retailers.png";
import { SearchCard } from './SearchCard/SearchCard';
import { CTACard } from './CTACard/CTACard';
import { InfoCard } from './InfoCard/InfoCard';
import "./MainPage.css";

interface IProps{
    setReference: (ref: string) => void;
    setHeaderTitle: (title: string) => void;
}

enum SearchTab {
    DIMENSION,
    PLATE
}

export function MainPage(props:IProps) {
    const[totalProducts, setTotalProducts] = useState(0);
    const[totalRetailers, setTotalRetailers] = useState(0);
    const [searchReference, setSearchReference] = useState("");
    let history = useHistory();

    const loadTotalTyresAndRetailers = useCallback(() => {
        const clientService = new ClientService();
        clientService.getTotalProductsAndRetailers().then(res => {
            setTotalProducts(res.totalProducts);
            setTotalRetailers(res.totalRetailers);
        });
    },[setTotalProducts, setTotalRetailers]);

    useEffect(( ) => {
        props.setHeaderTitle("Home");
        loadTotalTyresAndRetailers();
    },[])

     useEffect(() => {
         if(searchReference)
            history.push("Home/Search?reference=" + searchReference);
     },[searchReference, history])

    return(
        <div className="mainPage">
            <div className="leftCard">
                <SearchCard setReference={setSearchReference}/>
            </div>
            
            <div className="verticalSeparator"></div>
            
            <div className="rightCard">
                <div className="moreCard">
                    <div className="subscriptionCard">
                        <CTACard text={"Renove a sua subscrição"} actionText={"Renovar"} url={"/"}></CTACard>
                    </div>
                    
                    <div className="verticalSeparator"></div>
                    
                    <div className="infoCard">
                        <InfoCard title={"Pneus"} value={totalProducts} icon={tireIcon}></InfoCard>
                        <div className="horizontalSeparator"></div>
                        <InfoCard title={"Fornecedores"} value={totalRetailers} icon={retailerIcon}></InfoCard>
                    </div>
                </div>
            </div>
        </div>
    )
}