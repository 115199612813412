import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IKeyLabel } from '../Table';

import {ReactComponent as ArrowSVG} from '../../../../Assets/svgs/arrow_simple.svg';
import {ReactComponent as DoubleArrowSVG} from '../../../../Assets/svgs/arrow_double.svg';

import './TableFooter.css';

interface ITableFooterProps {
    paginationOptions: IKeyLabel[];
    totalItems: number;
    currentPage: number;
    itemsPage?: number;
    currentPageItemCount: number;
    onCurrentPageChanged?: (newPage: number) => void;
    onRowsPerPageChanged?: (rowsPerPage: number) => void;

    // currentRowsPerPage: any;
    // onRowsPerPageChange: (rowsPerPage: any) => void;
}


interface IPaginationButtonProps {
    className?: string;
    isDisabled?: boolean;
    onClick?: () => void;
    children: React.ReactNode;

}
function PaginationButton(props: IPaginationButtonProps){

    const cssDisabled= useCallback((isDisabled: boolean|undefined) => {
        return isDisabled ? "disabled" : "";
    }, []);

    const handleClick = useCallback((_: any) => {
        if(!props.isDisabled && props.onClick){
            props.onClick();
        }
    }, [props]);

    return (
        <div className={["n4mp-square", (props.className || ""), cssDisabled(props.isDisabled)].join(" ")} onClick={handleClick}>
            {props.children}
        </div>
    );
}

export function TableFooter(props: ITableFooterProps){

    const [totalPages, setTotalPages] = useState(0);
    const [selectedLength, setSelectedLength] = useState<number>(0);
    
    /* -----------------
    * Computed Values
    ----------------- */
    const itemRange = useMemo(() => {
        let first = selectedLength * props.currentPage;
        let second = first + props.currentPageItemCount;
        return (first+1) + '-' + second;
    }, [selectedLength, props.currentPage, props.currentPageItemCount])

    const pageLength = useMemo(() => {
        
        return props.totalItems;
    }, [props.totalItems])

    const hasNextPage = useMemo(() => {
        return props.currentPage < totalPages - 1;
    }, [props.currentPage, totalPages]);

    const hasPreviousPage = useMemo(() => {
        return props.currentPage > 0;
    }, [props.currentPage]);

    /* -----------------
    * Callbacks
    ----------------- */
    const onRowsPerPageChanged = useCallback((key: any) => {
        let res = props.paginationOptions.filter(opt => opt.key == key);
        var newPage = 0;
        if(res && res.length && props.onRowsPerPageChanged){
            props.onRowsPerPageChanged(res[0].label );
            setSelectedLength(res[0].label);
            onCurrentPageChanged(0);
        }
    }, [props, setSelectedLength]);

    /*const handleLengthChanged = useCallback((key: any) => {
        let res = props.paginationOptions.filter(opt => opt.key === key);
        if(res && res.length){
            setSelectedLength(res[0]);
        }
    }, [props, setSelectedLength])*/


    const onCurrentPageChanged = useCallback((newPage: number) => {
        if(props.onCurrentPageChanged)
            props.onCurrentPageChanged(newPage);
    }, [props]);


    /* -----------------
    * Effects
    ----------------- */
    useEffect(() => {
        setTotalPages( Math.ceil(props.totalItems / selectedLength));
        //setSelectedLength(( selectedLength))
    }, [props.totalItems, selectedLength])

    useEffect(() => {
        if(props.itemsPage){
            setSelectedLength(props.itemsPage)
        }
    }, [props.itemsPage])

    // useEffect(() => {
    //     if(props.itemsPage && props.itemsPage != selectedLength){
    //         setSelectedLength(props.itemsPage)
    //     }
    // }, [])





    /* -----------------
    * Render
    ----------------- */
    return (

        <div className="n4mp-table-footer">
            <div className="n4mp-table-footer-area">Items por pág. <select disabled={!props.totalItems} value={props.itemsPage} onChange={(ev) => onRowsPerPageChanged(ev.target.value)}>
                {props.paginationOptions.map((opt, idx) => <option key={idx} value={opt.key}>{opt.label}</option>)}
                </select>
            </div>
            <div className="n4mp-table-grow"></div>
            <div className="n4mp-table-footer-area">{itemRange} de {props.totalItems}
                <div className="n4mp-table-footer-pagination">
                    <PaginationButton isDisabled={!hasPreviousPage} className="reversed" onClick={() => onCurrentPageChanged(0)}> <DoubleArrowSVG/> </PaginationButton>
                    <PaginationButton isDisabled={!hasPreviousPage} className="reversed" onClick={() => onCurrentPageChanged(props.currentPage -1)}> <ArrowSVG/> </PaginationButton>
                    <PaginationButton isDisabled={!hasNextPage} onClick={() => onCurrentPageChanged(props.currentPage +1)}> <ArrowSVG/> </PaginationButton>
                    <PaginationButton isDisabled={!hasNextPage} onClick={() => onCurrentPageChanged(totalPages-1)}> <DoubleArrowSVG/> </PaginationButton>
                </div>
            </div>
        </div>

    );
}
