import React from 'react';


interface IProps {
    verticalPadding?:number,
    horizontalPadding?:number,
}

export default class DefaultLayout extends React.Component<IProps> {

    render() {
        return (
            <div
               /* style={{ padding:`${this.props.verticalPadding? this.props.verticalPadding+`rem`: `1.5rem`} ${this.props.horizontalPadding? this.props.horizontalPadding+`rem`: `1.5Rem`}`}} */>
                {this.props.children}
            </div>
        );
    }
}