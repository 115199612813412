import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TyreDTO } from '../../Models/Domain/TyreDTO';
import { ClientService } from '../../Services/ClientService';
import { ProductSearchRequestDTO } from "../../Models/API/Clients/ProductSearchRequestDTO";
import { ToCompareTyreDTO } from '../../Models/Domain/ToCompareTyreDTO';
import { Table } from '../../Components/Tables/Table/Table';
import { ContainerLayout } from '../../Layouts/Container/ContainerLayout';
import { TyreDescriptionTableCell } from './Components/TyreDescriptionTableCell';
import { ReactComponent as SaveSVG } from './../../Assets/svgs/bookmark.svg';
import { ReactComponent as MagSVG } from './../../Assets/svgs/magnifying-glass.svg';
import { ReactComponent as CloseSVG } from './../../Assets/svgs/close.svg';
import { Input } from '../../Components/Atoms/Input/Input';
import { IconButton, IconButtonType } from '../../Components/Atoms/IconButton/IconButton';
import { useHistory, useLocation } from 'react-router-dom';
import { ConditionalRender } from "../../Components/Directives/ConditionalRender";
import {ReactComponent as TrashSVG} from './../../Assets/svgs/trash.svg';
import { MenuItem } from "./InputSearch/InputSearch";
import { BudgetService } from '../../Services/BudgetService';
import { BudgetPostRequestDTO, TyreBudget } from '../../Models/API/Clients/BudgetPostRequestDTO';
import { ReactComponent as SaveBudgetSVG } from './../../Assets/svgs/content-save-all.svg';
import { SavePopUp } from '../../Components/Molecules/PopUp/SavePopUp';
import "./SearchPage.css";
import { useViewport } from "../../Hooks/UseViewPort";
import { useIdentityUserEmail } from '../../Infrastructure/Identity/Hooks/useIdentityUserEmail';
import { useGlobalPopup } from '../../Infrastructure/UI/Hooks/useGlobalPopup';
import { useGlobalLoading } from '../../Infrastructure/UI/Hooks/useGlobalLoading';
import { FullScreenLoader } from '../../Infrastructure/UI/Components/FullScreenLoader/FullScreenLoader';
import { SuccessPopUp } from '../../Components/Molecules/PopUp/SuccessPopUp';
import { FormField } from "./Components/FormField/FormField";
import { BudgetDTO } from "../../Models/API/Clients/BudgetPostResponseDTO";
import { RetailerAccessesService } from "../../Services/RetailerAccessesService";
import { CbOption, InputAutoComplete } from '../../Components/InputAutoComplete/InputAutoComplete';
import { setTokenSourceMapRange } from "typescript";
import { Checkbox } from "./Components/Checkbox/Checkbox";
import { ComboBox } from "../../Components/ComboBox/ComboBox";
import { AdvancedSearch } from "./Components/Accordion/AdvancedSearch";

interface IProps{
    // searchReference: string;
    setHeaderTitle: (title: string) => void;
}

let infoItems: MenuItem[] = [
    {
        text: "Medida",
        value: "keyWord",
        setText: "setKeyWord"
    },
    {
        text: "Fornecedor",
        value: "retailer",
        setText: "setRetailer"
    },
    {
        text: "Marca",
        value: "brand",
        setText: "setBrand"
    },
    {
        text: "Modelo",
        value: "model",
        setText: "setModel"
    },
    {
        text: "IC/IV",
        value: "icIv",
        setText: "setIcIv"
    },
    {
        text: "Estado",
        value: "state",
        setText: "setState"
    },
]

let infoDesc: MenuItem[] = [
    {
        text: "Desconto",
        value: "discount",
        setText: "setDiscount"
    },
]


export default function SearchPage(props:IProps) {
    const didInit = useRef(false);
    const location = useLocation();
    let history = useHistory();
    const {userEmail} = useIdentityUserEmail();
    const {width} = useViewport();

    const [isLoading, setIsLoading] = useState(false);
    const [totalTyres, setTotalTyres] = useState(0);
    const [totalTyres2, setTotalTyres2] = useState(0);
    const [tyresList, setTyresList] = useState<TyreDTO[]>([]);
    const [tyresList2, setTyresList2] = useState<TyreDTO[]>([]);

    const [maxPrice, setMaxPrice] = useState("");
    const [minPrice, setMinPrice] = useState("");
    const [errorMessageMaxPrice, setErrorMessageMaxPrice] = useState("");
    const [errorMessageMinPrice, setErrorMessageMinPrice] = useState("");
    const [validate, setValidate] = useState<boolean>(false);

    const [keyWord, setKeyWord] = useState("");
    const [keyWord2, setKeyWord2] = useState("");
    const [brand, setBrand] = useState("");
    const [retailer, setRetailer] = useState("");
    const [priceInterval, setPriceInterval] = useState<number[]>([0,10000]);
    const [model, setModel] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [icIv, setIcIv] = useState<string>("");
    const [total, setTotal] = useState<string>("");
    const [pageLength, setPageLength] = useState(10);
    const [actualPage, setActualPage] = useState(0);
    const [pageLength2, setPageLength2] = useState(5);
    const [actualPage2, setActualPage2] = useState(0);
    const [orderBy, setOrderBy] = useState<{column: string, dir: 'ASC' | 'DESC' | ''}>();
    const [clickSearch, setClickSearch] = useState<boolean>(true);
    const [stock, setStock] = useState<boolean>(true);
    const [runFlat, setRunFlat] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [errorMessageDiscount, setErrorMessageDiscount] = useState("");
    const[filters, setFilters] = useState<number>(0);
    const [finalPrice, setFinalPrice] = useState<boolean>(false);
    const [salePrice, setSalePrice] = useState<boolean>(false);
    

    const [showToCompare, setShowToCompare] = useState(false);
    const [tyresListToCompare, setTyresListToCompare] =useState<ToCompareTyreDTO[]>([]);

    const [discount, setDiscount] = useState<number>(0);
    const [discountPlaceHolder, setDiscountPlaceHolder] = useState<number>(0);
   
    const [profitMargin, setProfitMargin] = useState<number>(0)


    //--- Save Budget -----------------------------------------------------------
    const [allBudgets, setAllBudgets] = useState<BudgetDTO[]>([])
    const [budgetTyresList, setBudgetTyresList] = useState<TyreBudget[]>([])
    const [budgetName, setBudgetName] = useState("")
    
    //--- Popup e Full Screen Loader --------------------------------------------
    const {setShowPopup, setPopupContent} = useGlobalPopup();
    const {setShowFSL, setFSLContent} = useGlobalLoading();

    //---------------------------------------------------------------------------
    const validatePriceIntervals= useCallback(() => {
        let isValid = true;

        let realMaxPrice = maxPrice !== undefined && maxPrice !== "" ? Number.parseInt(maxPrice) : priceInterval[1];
        let realMinPRice = minPrice !== undefined && minPrice !== "" ? Number.parseInt(minPrice) : priceInterval[0];
        
        if(realMinPRice < priceInterval[0] || realMinPRice > priceInterval[1]){
            isValid = false;
            setErrorMessageMinPrice("Minimum price out of limits!");
        }
        else if(realMinPRice > realMaxPrice){
            isValid = false;
            setErrorMessageMinPrice("Minimum price greater than maximum price!");
        }
        else{
            setErrorMessageMinPrice("");
        }
        if(realMaxPrice < priceInterval[0] || realMaxPrice > priceInterval[1]){
           isValid = false;
           setErrorMessageMaxPrice("Maximum price out of limits!");
        }else{
            setErrorMessageMaxPrice("");
        }

        setValidate(isValid);

        return isValid;
    }, [minPrice, maxPrice, setValidate, setErrorMessageMaxPrice, setErrorMessageMinPrice]);
    //--- Input's info ----------------------------------------------------------
    const [allBrands, setAllBrands] = useState<CbOption[]>([])
    const [allRetailers, setAllRetailers] = useState<CbOption[]>([])
    const [allStates] = useState<CbOption[]>([{key: 0, label: ""}, {key: 1, label: "Novo"}, {key: 1, label: "Usado"}])
    const [allStatesStocks] = useState<CbOption[]>([{key: 0, label: "C/Stock"}, {key: 1, label: "S/Stock"}])


    //--- Load input's info -----------------------------------------------------
    const loadBrands = useCallback(() => {
        const clientService = new ClientService();
        let brands: CbOption[] = [];
        let idx = 0;
        clientService.getAllClientBrands().then(allBrands => {
            allBrands.brands.map(b => {
                if(b !== "")
                  brands.push({key: idx, label: b})
                  idx++;
                }
            )
        })
        setAllBrands(brands)
    },[setAllBrands]);


    const loadRetailers = useCallback(() => {
        const retailerService = new RetailerAccessesService();
        let retailers: CbOption[] = [];
        let idx = 0;
        retailerService.getAllRetailersAccesses().then(retailersAccesses =>{
            retailersAccesses.map(ra => {
                retailers.push({key: idx, label: ra.retailerName})
                idx++;
            })
            setAllRetailers(retailers)
        })
    },[setAllRetailers])
    //---------------------------------------------------------------------------

    const loadBudgets = useCallback(() => {
        const budgetService = new BudgetService();
        budgetService.getAllClientBudgets({desc: "", budgetId:null, dateTime: null, keyWord: ""}).then(res => {
            setAllBudgets(res.budgets);
        });
    },[setAllBudgets])


    const onInputChange = useCallback((event: any) => {
        let regex = /\d+(\.\d*)?/g;
        let arr = regex.exec(event) || [""];
        let val = "" + event;
        if(arr[0] == ""){
            return "";
        } else {
            return arr[0];
        }
    },[priceInterval]);

    const verifyDiscount = useCallback((event: any) => {
        if(event > 100){
            setErrorMessageDiscount("Discount above the limit!");
            setDiscount(0);
        } else {
            setErrorMessageDiscount("");
            setDiscount(event);
        }
        setDiscountPlaceHolder(event)
    },[priceInterval, setDiscount]);


    const getPriceRange = useCallback(() => {
        const clientService = new ClientService();
        clientService.getPriceRange().then(range => {
            if(range.minPrice != Number.parseInt(minPrice) || range.maxPrice != Number.parseInt(maxPrice)){
                setPriceInterval([Number.parseInt(range.minPrice.toFixed(2)),Number.parseInt(range.maxPrice.toFixed(2))]);
                setMaxPrice(""); //1.03
                setMinPrice(""); //985
            }   
        })
    },[priceInterval,minPrice, maxPrice, setPriceInterval])

    useEffect(() => {
        let query = new URLSearchParams(location.search);
        let reference = query.get("reference");
        if(keyWord !== reference){
            loadTyres(reference || "");
        }
        
    }, [location]);

    const verifyFilters = useCallback(() => {
        let numberOfFilters = 0;
        if(minPrice != ""){
            numberOfFilters++;
        }
        if(maxPrice != ""){
            numberOfFilters++;
        }
        if(retailer != ""){
            numberOfFilters++;
        }
        if(model != ""){
            numberOfFilters++;
        }
        if(icIv != ""){
            numberOfFilters++;
        }
        setFilters(numberOfFilters);

    },[minPrice, maxPrice, filters, retailer, model, icIv, setFilters])

    const loadTyres = useCallback((searchReference?:string, newSearch?: boolean) => {

        let query = new URLSearchParams(location.search);
        let reference = query.get("reference");

        if(reference != keyWord){
            history.push({search: "?reference=" + keyWord || searchReference});
        }
        if(keyWord2 != ""){
            history.push({search: "?reference=" + keyWord + "&&reference2=" + keyWord2 });
        }

        if(validatePriceIntervals()){
            setIsLoading(true);
            const clientService = new ClientService();
            clientService.getProfitMargin().then(profitMarginRes => {
                setProfitMargin(profitMarginRes)

                let searchWord = keyWord || searchReference;
                let realMaxPrice = maxPrice !== undefined && maxPrice !== "" ? Number.parseInt(maxPrice) : priceInterval[1];
                let realMinPRice = minPrice !== undefined && minPrice !== "" ? Number.parseInt(minPrice) : priceInterval[0];
                
                if(keyWord2 && keyWord2 != "" ){
                    setPageLength(tyresList2.length < 1 ? 5 : pageLength);
                    var request: ProductSearchRequestDTO = {
                        brand: brand,
                        model: model,
                        keyWord: searchWord,
                        retailer: retailer,
                        state: state,
                        icIv: icIv,
                        runFlat: runFlat,
                        minPrice: realMinPRice, //0
                        maxPrice: realMaxPrice, //10000
                        pageLength: tyresList2.length < 1 ? 5 : pageLength,
                        currentPage: actualPage,
                        orderBy: orderBy?.column,
                        orderDirection: orderBy?.dir.toString(),
                        stock: stock,
                    }

                    var request2: ProductSearchRequestDTO = {
                        brand: brand,
                        model: model,
                        keyWord: keyWord2,
                        runFlat: runFlat,
                        retailer: retailer,
                        state: state,
                        icIv: icIv,
                        minPrice: realMinPRice, //0
                        maxPrice: realMaxPrice, //10000
                        pageLength: pageLength2,
                        currentPage: actualPage2,
                        orderBy: orderBy?.column,
                        orderDirection: orderBy?.dir.toString(),
                        stock: stock,
                    }

                    
                    clientService.searchProducts(request).then(tableDTO => {
                    setTotalTyres(tableDTO.total);
                    let tyresListAux = tableDTO.rows.map(tyre => {
                        var tyreObj: TyreDTO = {
                            completeDescription: tyre.completeDescription,
                            retailerName: tyre.retailerName,
                            fuelConsumption: tyre.fuelConsumption,
                            grip: tyre.grip,
                            rollingNoise: tyre.rollingNoise,
                            model: tyre.model,
                            brand: tyre.brand,
                            reference: tyre.reference,
                            link: tyre.link,
                            priceNet: tyre.priceNet,
                            icIv: tyre.icIv,
                            state: tyre.state,
                            stock: tyre.stock
                        }
                        return tyreObj;
                    })

                        var min = tableDTO.minPrice * (100 + profitMarginRes) / 100;
                        var max = tableDTO.maxPrice * (100 + profitMarginRes) / 100;
                        //setPriceInterval([tableDTO.minPrice,tableDTO.maxPrice]);
                        setTyresList(tyresListAux);
                        setIsLoading(false);
                    });

                    clientService.searchProducts(request2).then(tableDTO => {
                        setTotalTyres2(tableDTO.total);
                        let tyresListAux2 = tableDTO.rows.map(tyre => {
                            var tyreObj: TyreDTO = {
                                completeDescription: tyre.completeDescription,
                                retailerName: tyre.retailerName,
                                fuelConsumption: tyre.fuelConsumption,
                                grip: tyre.grip,
                                rollingNoise: tyre.rollingNoise,
                                model: tyre.model,
                                brand: tyre.brand,
                                reference: tyre.reference,
                                link: tyre.link,
                                priceNet: tyre.priceNet,
                                icIv: tyre.icIv,
                                state: tyre.state,
                                stock: tyre.stock
                            }
                            return tyreObj;
                        })
    
                            var min = tableDTO.minPrice * (100 + profitMarginRes) / 100;
                            var max = tableDTO.maxPrice * (100 + profitMarginRes) / 100;
                            //setPriceInterval([tableDTO.minPrice,tableDTO.maxPrice]);
                            setTyresList2(tyresListAux2);
                            setIsLoading(false);
                        });
                }
                else{

                    var request: ProductSearchRequestDTO = {
                        brand: brand,
                        model: model,
                        keyWord: searchWord,
                        retailer: retailer,
                        state: state,
                        icIv: icIv,
                        runFlat: runFlat,
                        minPrice: realMinPRice, //0
                        maxPrice: realMaxPrice, //10000
                        pageLength: pageLength,
                        currentPage: actualPage,
                        orderBy: orderBy?.column,
                        orderDirection: orderBy?.dir.toString(),
                        stock: stock,
                    }

                    clientService.searchProducts(request).then(tableDTO => {
                        setTotalTyres(tableDTO.total);
                        let tyresListAux = tableDTO.rows.map(tyre => {
                            var tyreObj: TyreDTO = {
                                completeDescription: tyre.completeDescription,
                                retailerName: tyre.retailerName,
                                fuelConsumption: tyre.fuelConsumption,
                                grip: tyre.grip,
                                rollingNoise: tyre.rollingNoise,
                                model: tyre.model,
                                brand: tyre.brand,
                                reference: tyre.reference,
                                link: tyre.link,
                                priceNet: tyre.priceNet,
                                icIv: tyre.icIv,
                                state: tyre.state,
                                stock: tyre.stock
                            }
                            return tyreObj;
                        })

                            var min = tableDTO.minPrice * (100 + profitMarginRes) / 100;
                            var max = tableDTO.maxPrice * (100 + profitMarginRes) / 100;
                            //setPriceInterval([tableDTO.minPrice,tableDTO.maxPrice]);
                            setTyresList(tyresListAux);
                            setIsLoading(false);
                        });
                        verifyFilters();
                }
            }).catch(err => {
                setIsLoading(false);
            });
        }
    },[model, icIv,runFlat, keyWord2, state, stock,setPageLength2, setPageLength, setTotalTyres2 ,setTyresList2,
         validatePriceIntervals ,setMinPrice,minPrice, maxPrice, setMaxPrice, setPriceInterval, brand, priceInterval,
          retailer, setTyresList, setTotalTyres, actualPage, actualPage2, pageLength, pageLength2,  orderBy, keyWord, setIsLoading]);
    
    useEffect(( ) => {
        if(!didInit.current) return;
        //loadProfitMargin()
        loadTyres()
        setClickSearch(false);
    },[actualPage, pageLength,actualPage2, pageLength2, orderBy])


    useEffect(() => {
        console.log("on Init");
        didInit.current = true;
        props.setHeaderTitle("Pesquisa");
        

        let query = new URLSearchParams(location.search);
        let reference = query.get("reference");
        let reference2 = query.get("reference2");
        console.log("useEffect 0", reference)
        if(reference){
            getPriceRange();
            setKeyWord(reference);
            if(reference2){
                setKeyWord2(reference2);
            }
            
            loadTyres(reference);
        } else {
            getPriceRange();
            loadTyres();
        }
        loadBrands();
        loadUserSettings();
        loadRetailers();
        loadBudgets();
    }, []);

    const onTyreSelected = useCallback((row: TyreDTO, discount: number, profitMargin: number) => {
        setShowToCompare(true);
        var aux: ToCompareTyreDTO[] = [];
        var aux2: ToCompareTyreDTO[] = [];
        tyresListToCompare.map(tyre => aux.push(tyre));

        let tyreToCompare: ToCompareTyreDTO ={
            brand: row.brand,
            completeDescription: row.completeDescription,
            fuelConsumption: row.fuelConsumption,
            grip: row.grip,
            rollingNoise: row.rollingNoise,
            icIv: row.icIv,
            link: row.link,
            model: row.model,
            priceNet: row.priceNet,
            reference: row.reference,
            retailerName: row.retailerName,
            state: row.state,
            stock: row.stock,
            discount: discount,
            profitMargin: profitMargin
        }

        var tyreAux = aux.find(p => p.completeDescription === tyreToCompare.completeDescription && p.retailerName === tyreToCompare.retailerName);
        if(!tyreAux || aux.length === 0) aux.push(tyreToCompare);
          
        // var res = aux.reduce((result: any, currentValue) => {
            
        //     (result[currentValue["brand"]] = result[currentValue["brand"]] || []).push(
        //       currentValue
        //     );
        //     // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        //     return result;
        //   }, {});

        //   console.log(res);

        // //const res = groupBy(aux);

        // res.forEach((item: ToCompareTyreDTO) => {
        //     aux2.push(item);
        // });

        setTyresListToCompare(aux);
    },[setShowToCompare, setTyresListToCompare, tyresListToCompare]);

    useEffect(( ) => {
        var aux: number = 0;

        tyresListToCompare.map(tyre => {
            aux += parseFloat(((tyre.priceNet * (100 + tyre.profitMargin) / 100) - tyre.priceNet * discount / 100).toFixed(2));
        });
        setTotal("Preço Total: " + aux.toFixed(2) + " €");
    },[tyresListToCompare, discount])

    // useEffect(( ) => {
    //     if(selectedStock.startsWith("C/Stock")){
    //         setStock(true);
    //     }
    //     else{
    //         setStock(false);
    //     }
    // },[selectedStock])


    const handleTyreDelete = useCallback((row: TyreDTO) => {
        setTyresListToCompare(tyresListToCompare.filter(el => el !== row))
        console.log(tyresListToCompare.length)
        if(tyresListToCompare.length === 1){
            setShowToCompare(false);
        }
    },[setTyresListToCompare,tyresListToCompare])


    const handleEnterPressed = useCallback((ev: React.KeyboardEvent<Element> ) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();
            setActualPage(0);
            loadTyres();
            console.log("handleEnterPressed")
            return false;
          }
    }, [setActualPage, loadTyres]);


    const cleanFilters = useCallback(() => {
        setKeyWord("");
        setKeyWord2("");
        setRetailer("");
        setBrand("");
        setModel("");
        setIcIv("");
        setState("");
        setStock(true);
        setRunFlat(false);
        setMinPrice("");
        setMaxPrice("");
        setDiscount(0);
        setPageLength(10);
        setDiscountPlaceHolder(0);
        setTyresList2([]);
        setOrderBy({column: '', dir: ''});
        //history.replace("Search");
        history.push({search: ""});
    },[ priceInterval, setRunFlat,setPageLength, setStock,setKeyWord2, setMinPrice,setOrderBy,
         setMaxPrice, setKeyWord, setRetailer, setBrand,setModel, setIcIv, setState, setDiscount, setDiscountPlaceHolder])


    const addBudget = useCallback(async (budgetTyresList: TyreBudget[], budgetName: string) => {
        setShowFSL(true)
        setFSLContent(<FullScreenLoader />)

        var request: BudgetPostRequestDTO = {
            description: budgetName,
            tyresList: budgetTyresList
        }
    
        const budgetService = new BudgetService();
        if(userEmail){
            try{
                await budgetService.postBudget(request, userEmail).then(r => {
                    setShowFSL(false)
                    setShowPopup(true)
                    setPopupContent(<SuccessPopUp title={"Adicionar Orçamento"} message={"Orçamento adicionado com sucesso!"} onClose={() => setShowPopup(false)} />);
                });
            }catch(res: any){
                setShowFSL(false)
                setShowPopup(true)
                setPopupContent(<SuccessPopUp title={"Adicionar Orçamento"} message={"Ocorreu um erro. Orçamento não adicionado!"} onClose={() => setShowPopup(false)} />);
            }
        }
    },[userEmail, setShowFSL, setFSLContent, setShowPopup, setPopupContent])


    const verifyRepeatedBudgetName = useCallback((budgetName: string) : boolean => {
        let repeatedName = allBudgets.filter(b => b.description === budgetName)
        return repeatedName.length > 0 ? true : false;
    },[allBudgets])


    const addBudgetDescription = useCallback((auxTyresList: TyreBudget[]) => {
        let budgetName = "";
        setShowPopup(true)
        setPopupContent(
            <SavePopUp
                verifyRepeatedName={verifyRepeatedBudgetName} 
                setShowPopUp={() => setShowPopup(false)}
                setBudgetName={(desc) => { setBudgetName(desc); budgetName = desc} }
                onSave={() => { addBudget(auxTyresList, budgetName)}}
            />
        );
    },[setShowPopup, setPopupContent, setBudgetName, addBudget, verifyRepeatedBudgetName])


    const onSaveBudget = useCallback((list: ToCompareTyreDTO[]) => {
        let auxTyresList : TyreBudget[] = list.map((obj) : TyreBudget => {
            return ({
                completeDescription: obj.completeDescription,
                brand: obj.brand,
                discount: obj.discount,
                fuelConsumption: obj.fuelConsumption,
                grip: obj.grip,
                icIv: obj.icIv,
                link: obj.link,
                model: obj.model,
                priceNet: obj.priceNet,
                profitMargin: obj.profitMargin,
                reference: obj.reference,
                retailerName: obj.retailerName,
                rollingNoise: obj.rollingNoise,
                state: obj.state,
                stock: obj.stock,
                pvpPrice: (obj.priceNet * (100 + obj.profitMargin) / 100),
                finalPrice: ((obj.priceNet * (100 + obj.profitMargin) / 100) - obj.priceNet * obj.discount / 100),
            })
        });
        setBudgetTyresList(auxTyresList);
        addBudgetDescription(auxTyresList)
    },[setBudgetTyresList, addBudgetDescription]);

    const loadUserSettings = useCallback(() => {
        const clientService = new ClientService();
        clientService.getUserSettings().then(c => {
            setFinalPrice(c.finalPrice);
            setSalePrice(c.salePrice);
        })
    },[setFinalPrice, setSalePrice])


    const columns = useMemo(() => {
        console.log(width);
        return   [
            {
                key: 'reference',
                headerLabel: 'Referência',
                isHidden: width < 900,
                sortable: true,
                valueTransform: (value: ToCompareTyreDTO) => <>{value.reference}</>
            },
            {
                key: 'description',
                headerLabel: 'Descrição',
                sortable: true,
                valueTransform: (value: ToCompareTyreDTO) => <TyreDescriptionTableCell item={value}/>
            },
            {
                key: 'retailer',
                headerLabel: 'Distribuidor',
                sortable: true,
                valueTransform: (value: ToCompareTyreDTO) => <>{value.retailerName || '-'}</>
            },
            {
                key: 'brand',
                headerLabel: 'Marca',
                sortable: true,
                isHidden: width < 900,
                valueTransform: (value: ToCompareTyreDTO) => <>{value.brand || '-'}</>
            },
            {
                key: 'state',
                headerLabel: 'Estado',
                isHidden: width < 900,
                sortable: true,
                valueTransform: (value: ToCompareTyreDTO) => <>{value.state || '-'}</>
            },
            {
                key: 'stock',
                headerLabel: 'Stock',
                isHidden: width < 900,
                sortable: true,
                valueTransform: (value: ToCompareTyreDTO) => <>{value.stock || '-'}</>,
            },
            {
                key: 'netprice',
                headerLabel: 'Preço Net',
                isHidden: width < 800,
                sortable: true,
                valueTransform: (value: ToCompareTyreDTO) => <>{value.priceNet.toFixed(2)} €</>,
            },
            // {
            //     key: 'pvpprice',
            //     headerLabel: 'Preço Venda',
            //     isHidden: width < 900,
            //     sortable: true,
            //     valueTransform: (value: ToCompareTyreDTO) => <>{(value.priceNet * (100 + value.profitMargin) / 100).toFixed(2)} €</>,
            // },
            // {
            //     key: 'finalprice',
            //     headerLabel: 'Preço Final ' + '\n (c/Desc)',
            //     sortable: true,
            //     valueTransform: (value: ToCompareTyreDTO) => <>{((value.priceNet * (100 + value.profitMargin) / 100) - value.priceNet * value.discount / 100).toFixed(2)} €  {value.discount ? <>({value.discount}%)</> : null}</>,
            // },
            {
                key: 'saveAction',
                headerLabel: <div className="save" onClick={() => onSaveBudget(tyresListToCompare)}><SaveBudgetSVG/></div>,
                sortable: false,
                valueTransform: (value: ToCompareTyreDTO) => <div className="n4mp-table-cell-align_center" onClick={() => {handleTyreDelete(value)}}>
                    <div className="n4mp-circle red">
                        <div className="smallSVG">
                            <TrashSVG/>
                        </div>
                    </div>
                </div>,
            }
        ];
    }, [width, tyresListToCompare])


    return(
        <div className= "second-navbar">

            <ConditionalRender if={!!tyresListToCompare.length}>
                <div className="budget-header">
                    <h2 className="n4mp-h2 margin-back">Selecionados ({tyresListToCompare.length})</h2>
                    <FormField className="filter-input">
                        {/* <Input type="text" className="filter-input" placeholder={"Desconto"} value={discountPlaceHolder} onChange={(e) => {verifyDiscount(Number.parseInt(e.target.value || "0")); /*setDiscountPlaceHolder(e.target.value)}} /> }*/}
                        <Input type="text" className="filter-input" placeholder="Desconto" value={discountPlaceHolder || ""} onChange={(e) => {verifyDiscount(Number.parseInt(e.target.value || "0"))}} />
                        {errorMessageDiscount
                            ? <h5 className="error-message">{errorMessageDiscount}</h5>
                            : null
                        }
                    </FormField>
                </div>
                <ContainerLayout>
                    <Table
                        className="n4mp-table-nopagination"
                        isLoading={isLoading}
                        currentPage={actualPage}
                        onCurrentPageChanged={setActualPage}
                        dataTotalItems={tyresListToCompare.length}
                        dataList={tyresListToCompare}
                        columnDefinition={columns}
                    />
                </ContainerLayout>
                <div className="content-total">
                    <div className="total-spacer"/>
                    <FormField className="total-input">
                        {/* <Input type="text" className="filter-input" placeholder={"Desconto"} value={discountPlaceHolder} onChange={(e) => {verifyDiscount(Number.parseInt(e.target.value || "0")); /*setDiscountPlaceHolder(e.target.value)}} /> }*/}
                        <Input type="text" className="filter-input" placeholder={total} value={total} disabled={true}/>
                    </FormField>
                </div>
            </ConditionalRender>


            <ConditionalRender if={!!tyresListToCompare.length}>
                <h2 className="n4mp-h2">Pesquisa</h2>
            </ConditionalRender>


            <div className="filter-container">
                <FormField className="filter-input">
                    <Input type="text" className="filter-input" placeholder="Medida" value={keyWord}  onChange={(ev) => setKeyWord(ev.target.value)} onKeyPress={handleEnterPressed} />
                </FormField>
                <FormField className="filter-input">
                    <Input type="text" className="filter-input" placeholder=" 2º Medida" value={keyWord2}  onChange={(ev) => {setKeyWord2(ev.target.value)}} onKeyPress={handleEnterPressed} />
                </FormField>
                <FormField className="filter-input">
                    <InputAutoComplete css={""} placeholder={"Marca"} selectedOp={brand} optionsList={allBrands} onChange={setBrand} />
                </FormField>
                <FormField className="filter-input">
                    <ComboBox css={""} placeholder={"Estado"} selectedOp={state} optionsList={allStates} onChange={setState} />
                </FormField>
                <FormField className="filter-input">
                    <Checkbox
                        isChecked={runFlat}
                        text="Run Flat"
                        onToogle={setRunFlat}
                    />
                </FormField>
                <FormField className="filter-input-button">
                <IconButton  text="Limpar" icon={<CloseSVG/>}  type={IconButtonType.SECONDARY}  onClick={() => cleanFilters()} />
                </FormField>
                <FormField className="filter-input-button">
                <IconButton  text="Pesquisar" icon={ <MagSVG/>}  type={IconButtonType.PRIMARY} onClick={() => {setActualPage(0); setClickSearch(true); loadTyres();}} isLoading={isLoading}/>
                </FormField>
            </div>

            <div className="filter-container">
                <ContainerLayout>
                    <AdvancedSearch
                        Title="Pesquisa Avançada"
                        icIv={icIv}
                        model={model}
                        retailer={retailer}
                        allRetailers={allRetailers}
                        stock={stock}
                        setStock={setStock}
                        handleEnterPressed={handleEnterPressed}
                        setIcIv={setIcIv}
                        setModel={setModel}
                        setRetailer={setRetailer}
                        priceInterval={priceInterval}
                        maxPrice={maxPrice}
                        minPrice={minPrice}
                        setMaxPrice={setMaxPrice}
                        setMinPrice={setMinPrice}
                        filters={filters}
                    />
                </ContainerLayout>
            </div>

            <ContainerLayout>
                <Table
                    isLoading={isLoading}
                    currentPage={actualPage}
                    itemsPage={pageLength}
                    onOrderChange={(ordercol, orderdir) => {
                        setOrderBy({column: ordercol, dir:orderdir});
                    }}
                    onClickSearch={clickSearch}
                    onCurrentPageChanged={setActualPage}
                    onRowsPerPageChange={setPageLength}
                    dataTotalItems={totalTyres}
                    dataList={tyresList}
                    columnDefinition={
                        [
                            {
                                key: 'Reference',
                                headerLabel: 'Referência',
                                sortable: true,
                                isHidden: width < 1200,
                                valueTransform: (value: TyreDTO) => <>{value.reference}</>
                            },
                            {
                                key: 'Desc',
                                headerLabel: 'Descrição',
                                sortable: true,
                                valueTransform: (value: TyreDTO) => <TyreDescriptionTableCell item={value}/>
                            },
                            {
                                key: 'Retailer.RetailerName',
                                headerLabel: 'Distribuidor',
                                sortable: true,
                                valueTransform: (value: TyreDTO) => <>{value.retailerName || '-'}</>
                            },
                            {
                                key: 'Brand',
                                headerLabel: 'Marca',
                                sortable: true,
                                isHidden: width < 900,
                                valueTransform: (value: TyreDTO) => <>{value.brand || '-'}</>
                            },
                            {
                                key: 'State',
                                headerLabel: 'Estado',
                                sortable: true,
                                isHidden: width < 1300,
                                valueTransform: (value: TyreDTO) => <>{value.state || '-'}</>
                            },
                            {
                                key: 'Stock',
                                headerLabel: 'Stock',
                                sortable: true,
                                isHidden: width < 1200,
                                valueTransform: (value: TyreDTO) => <>{value.stock || '-'}</>,
                            },
                            {
                                key: 'PriceNet',
                                headerLabel: 'Preço Net',
                                sortable: true,
                                isHidden: width < 1200,
                                valueTransform: (value: TyreDTO) => <>{value.priceNet.toFixed(2)} €</>,
                            },
                            {
                                key: 'pvpprice',
                                headerLabel: 'Preço Venda',
                                sortable: true,
                                isHidden: !salePrice,
                                valueTransform: (value: TyreDTO) => <>{(value.priceNet * (100 + profitMargin) / 100).toFixed(2)} €</>,
                            },
                            {
                                key: 'finalprice',
                                headerLabel: 'Preço Final ' + (discount < 100 ? `\nc/Desc: ${discount}%` : '' ) ,
                                sortable: true,
                                isHidden: !finalPrice,
                                valueTransform: (value: TyreDTO) => <>{((value.priceNet * (100 + profitMargin) / 100) - value.priceNet * discount / 100).toFixed(2)} €</>,
                            },
                            {
                                key: 'saveAction',
                                headerLabel: '',
                                sortable: false,
                                valueTransform: (value: TyreDTO) => <div className="n4mp-table-cell-align_center" onClick={() => {onTyreSelected(value, discount, profitMargin)}}>
                                    <div className="n4mp-circle light"><SaveSVG/></div>
                                </div>
                            }
                        ]
                    }
                    paginationOptions={[
                        {key:5, label:5},
                        {key:10, label:10},
                        {key:20, label:20},
                        {key:50, label:50}
                    ]}
                />
            </ContainerLayout>

            <div className="spacerTables"></div>

            <ConditionalRender if={tyresList2.length > 0}>
                <ContainerLayout>
                    <Table
                        isLoading={isLoading}
                        currentPage={actualPage2}
                        itemsPage={pageLength2}
                        onOrderChange={(ordercol, orderdir) => {
                            setOrderBy({column: ordercol, dir:orderdir});
                        }}
                        onClickSearch={clickSearch}
                        onCurrentPageChanged={setActualPage2}
                        onRowsPerPageChange={setPageLength2}
                        dataTotalItems={totalTyres2}
                        dataList={tyresList2}
                        columnDefinition={
                            [
                                {
                                    key: 'Reference',
                                    headerLabel: 'Referência',
                                    sortable: true,
                                    isHidden: width < 1200,
                                    valueTransform: (value: TyreDTO) => <>{value.reference}</>
                                },
                                {
                                    key: 'Desc',
                                    headerLabel: 'Descrição',
                                    sortable: true,
                                    valueTransform: (value: TyreDTO) => <TyreDescriptionTableCell item={value}/>
                                },
                                {
                                    key: 'Retailer.RetailerName',
                                    headerLabel: 'Distribuidor',
                                    sortable: true,
                                    valueTransform: (value: TyreDTO) => <>{value.retailerName || '-'}</>
                                },
                                {
                                    key: 'Brand',
                                    headerLabel: 'Marca',
                                    sortable: true,
                                    isHidden: width < 900,
                                    valueTransform: (value: TyreDTO) => <>{value.brand || '-'}</>
                                },
                                {
                                    key: 'State',
                                    headerLabel: 'Estado',
                                    sortable: true,
                                    isHidden: width < 1300,
                                    valueTransform: (value: TyreDTO) => <>{value.state || '-'}</>
                                },
                                {
                                    key: 'Stock',
                                    headerLabel: 'Stock',
                                    sortable: true,
                                    isHidden: width < 1200,
                                    valueTransform: (value: TyreDTO) => <>{value.stock || '-'}</>,
                                },
                                {
                                    key: 'PriceNet',
                                    headerLabel: 'Preço Net',
                                    sortable: true,
                                    isHidden: width < 1200,
                                    valueTransform: (value: TyreDTO) => <>{value.priceNet.toFixed(2)} €</>,
                                },
                                // {
                                //     key: 'pvpprice',
                                //     headerLabel: 'Preço Venda',
                                //     sortable: true,
                                //     isHidden: width < 1300,
                                //     valueTransform: (value: TyreDTO) => <>{(value.priceNet * (100 + profitMargin) / 100).toFixed(2)} €</>,
                                // },
                                // {
                                //     key: 'finalprice',
                                //     headerLabel: 'Preço Final ' + (discount < 100 ? `\nc/Desc: ${discount}%` : '' ) ,
                                //     sortable: true,
                                //     valueTransform: (value: TyreDTO) => <>{((value.priceNet * (100 + profitMargin) / 100) - value.priceNet * discount / 100).toFixed(2)} €</>,
                                // },
                                {
                                    key: 'saveAction',
                                    headerLabel: '',
                                    sortable: false,
                                    valueTransform: (value: TyreDTO) => <div className="n4mp-table-cell-align_center" onClick={() => {onTyreSelected(value, discount, profitMargin)}}>
                                        <div className="n4mp-circle light"><SaveSVG/></div>
                                    </div>
                                }
                            ]
                        }
                        paginationOptions={[
                            {key:5, label:5},
                            {key:10, label:10},
                            {key:20, label:20},
                            {key:50, label:50}
                        ]}
                    />
                </ContainerLayout>
            </ConditionalRender>
        </div>
    )
}
