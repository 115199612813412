import React from "react";
import "./PopUp.css";
import Button from "../../Components/Buttons/Button";

import PopUpContentBox from './PopUpContentBox';

interface IProp {
  buttons?: Action[],
  onClose: () => void,
}

export interface Action{
  label: string,
  theme?:'BorderGold' | 'Gold',
  image?:any,
  onClick:()=>void
}

export default class PopUp extends React.Component<IProp> {

  render() {
    return (
    <PopUpContentBox onClose={() => this.props.onClose()}>
        {this.props.children ?
            <div className="popUp-children">
            {this.props.children}
            </div>    
        :
        null
        }
        <div className="popUp-buttonsInline">
            {this.props.buttons?.map((btn,idx) =>
              <Button name={btn.label} theme={btn.theme} onClick={btn.onClick} ></Button>
            )}
        </div>
    </PopUpContentBox>
  );
  }
}