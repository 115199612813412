import { SidebarButton } from "./SidebarButton";
import n4mpLogo from './../../../Assets/logos/tyrevalue-multi-dark.png';
import "./Sidebar.css";

export interface MenuItem {
    toLink: string,
    url: string,
    icon: string,
    imgAltName: string
    svg?: any
}

interface IProps{
    itemsTop: MenuItem[];
    itemsBottom: MenuItem[];
}

export function Sidebar(props:IProps){
    return(
        <div className="menu">
            <div className="side-logo">
                <img src={n4mpLogo} className="sidebar-logo" alt="n4-marketplace"/>
            </div>

            <div className="top">
            {
                props.itemsTop.map((item, idx) =>
                    <SidebarButton text={item.icon} url={item.toLink} iconSVG={item.svg} ></SidebarButton>
                )
            }
            </div>

            <div className="bottom">
            {
                props.itemsBottom.map((item, idx) =>
                    <SidebarButton text={item.icon} url={item.toLink} iconSVG={item.svg} ></SidebarButton>
                )
            }
            </div>
        </div>
    )
}

/****************** OK *******************/
