import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import {ReactComponent as SettingsSvg} from '../../Assets/svgs/settings.svg';
import {ReactComponent as LogoutSvg} from '../../Assets/svgs/logout.svg';
import {ReactComponent as UserSvg} from '../../Assets/svgs/user.svg';
import "./DropdownMenu.css";
import { useIdentityUserDisplayName } from '../../Infrastructure/Identity/Hooks/useIdentityUserDisplayName';

const StyledMenu = withStyles({
    paper: {
        width: '270px',
        borderRadius: '15px',
        marginTop: '30px'
    },
    root: {
        "& .MuiPaper-root": {
            top: "80px",
            right: '0px',
            background: '#FFFFFF 0% 0% no-repeat padding-box;',
        }
    }
})((props: MenuProps) => (
    <Menu
        elevation={8}
        PopoverClasses={ {paper: 'menuPopover'}}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export interface MenuItem {
    toLink: string,
    url: string,
    text: string,
    imgAltName: string
    svg?: any
}

let menuCard: MenuItem[] = [
    {
        text: "Definições",
        url: "Definições",
        imgAltName: "settings",
        toLink: "/app/Home/Settings",
        svg: <SettingsSvg />
    },
    {
        text: "Logout",
        url: "Sair",
        imgAltName: "logout",
        toLink: "/app/Home/Logout",
        svg: <LogoutSvg />
    }  
]

/*** para user props */
let userCard: MenuItem[] = [
  {
      text: "User",
      url: "User",
      imgAltName: "user",
      toLink: "/Assets/svgs/user.svg",
      svg: <UserSvg/>
  }
]
/*********************/



interface IProps{
    anchor: null | HTMLElement;
    handleClickUsername: (event: React.MouseEvent<HTMLElement>) => void;
    handleItemClick: (option: string) => void;
    handleClose: () => void;
}

//TODO: receber props do user (nome, email, svg)
export function DropdownMenu(props: IProps) {
    const { userDisplayName } = useIdentityUserDisplayName();

    return (
        <StyledMenu
            id="customized-menu"
            anchorEl={props.anchor}
            keepMounted
            open={Boolean(props.anchor)}
            onClose={props.handleClose}
        > 
                <div className="content-user">
                    <div className="user-header">
                        <div className="user-header-svg"><UserSvg/></div>
                        <div className="user-info">
                            <div className="user-name">{userDisplayName}</div>
                            <div className="user-email">-</div>
                        </div>
                    </div>
                    <div className="separator-user"/>
                    <div className="options-cards">
                        <div className="options-info" onClick={() => props.handleItemClick(menuCard[0].url)}>
                            <div className="option-icon">{menuCard[0].svg}</div>
                            <div className="option-text">{menuCard[0].text}</div>
                        </div>
                        <div className="options-info" onClick={() => props.handleItemClick(menuCard[1].url)}>
                            <div className="option-icon">{menuCard[1].svg}</div>
                            <div className="option-text">{menuCard[1].text}</div>
                        </div>
                    </div>
                </div>
        </StyledMenu>
    );
}

/****************** OK *******************/