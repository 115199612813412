import './TyreDescriptionTableCell.css';
import { TyreDTO } from '../../../Models/Domain/TyreDTO';
import {ReactComponent as LinkSVG} from './../../../Assets/svgs/link.svg';
import {ReactComponent as RainSVG} from './../../../Assets/svgs/cloud-showers-heavy-solid.svg';
import {ReactComponent as FuelSVG} from './../../../Assets/svgs/gas-pump-solid.svg';
import {ReactComponent as SoundSVG} from './../../../Assets/svgs/volume-up-solid.svg';
import { useCallback } from 'react';

interface ITyreDescriptionTableCellProps {
    item: TyreDTO;
}

export function TyreDescriptionTableCell(props: ITyreDescriptionTableCellProps){

    const handleLinkClick = useCallback(() => {
        window.open(props.item.link, '_blank');
    }, [props.item])

    return (
        <div className="tyre-description-table-cell">
            <div className="tyre-description-table-cell-icon">
                <div className="n4mp-circle black pointer" onClick={handleLinkClick}>
                    <LinkSVG/>
                </div>
            </div>
            <div className="tyre-description-table-cell-description">
                <div className="tyre-description-table-cell-title">
                    {props.item.completeDescription}
                </div>
                <div className="tyre-description-table-cell-properties">
                    <div className="n4mp-elipse">
                        <FuelSVG/> {props.item.fuelConsumption || '-'}
                    </div>

                    <div className="n4mp-elipse">
                        <RainSVG/> {props.item.grip || '-'}
                    </div>

                    <div className="n4mp-elipse">
                        <SoundSVG/> {props.item.rollingNoise || '-'}
                    </div>
                </div>
            </div>
        </div>
    );
}
